// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.eligibility-unverified {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 13px;
  background: #fff;
  flex-shrink: 0;
  color: #262626;
  padding: 3px 10px;
  .eligibility-unverified-icon {
    margin-right: 5px;
    width: 14px;
    height: 14px;
    color: red;
  }
}

.complete-worker-name {
  flex: none;
}
`, "",{"version":3,"sources":["webpack://./src/routes/Agency/Worker/index.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,uBAAuB;EACvB,mBAAmB;EACnB,mBAAmB;EACnB,gBAAgB;EAChB,cAAc;EACd,cAAc;EACd,iBAAiB;EACjB;IACE,iBAAiB;IACjB,WAAW;IACX,YAAY;IACZ,UAAU;EACZ;AACF;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".eligibility-unverified {\n  display: inline-flex;\n  justify-content: center;\n  align-items: center;\n  border-radius: 13px;\n  background: #fff;\n  flex-shrink: 0;\n  color: #262626;\n  padding: 3px 10px;\n  .eligibility-unverified-icon {\n    margin-right: 5px;\n    width: 14px;\n    height: 14px;\n    color: red;\n  }\n}\n\n.complete-worker-name {\n  flex: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
