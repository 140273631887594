import { useReactiveVar } from '@apollo/client';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { useFeatureValue } from '@growthbook/growthbook-react';
import { addDays, formatISO as formatISO8601, format } from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';

import DashboardFilters from '../DashboardFilters';
import TagsFilter from '../DashboardFilters/TagsFilter';
import ShiftGrid from './ShiftGrid';

import AccountStateError from '@/components/AccountStateError';
import Card from '@/components/Card';
import IconicButton from '@/components/IconicButton';
import Stack from '@/components/Stack';
import { Body } from '@/components/Typography';
import { setRegions } from '@/constants/filters';
import { TIME_TO_REFRESH } from '@/constants/general';
import {
  useListShiftsByWeekQuery,
  useRegionLazyQuery,
  useNeedsApprovalQuery,
  useGetUserFilterQuery,
} from '@/graphql';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import useAuth from '@/hooks/useAuth';
import useFilters from '@/hooks/useFilters';
import useMediaQuery from '@/hooks/useMediaQuery';
import {
  selectRefetchDashboard,
  setRefetchDashboardData,
} from '@/store/slices/layoutSlice';
import {
  CurrentCustomerAdminFragment,
  FillStatusEnum,
  JobDashboardStatusEnum,
  JobStatusEnum,
  JobFilterSetInput,
  UserFilterTypeEnum,
  VisibilityStatusEnum,
} from '@/types/graphql';
import {
  getAccountErrorTitle,
  getAccountErrorDesc,
  hasValidCustomerAccountStatus,
} from '@/util/accountstatus';
import {
  currentAdminVar,
  currentAgencyVar,
  currentTenantVar,
} from '@/util/apollo/cache';
import { MONDAY_OF_WEEK } from '@/util/constants';
import ls, { ACCOUNT_DISABLED } from '@/util/localstorage';
import NeedsApprovalAlert from '@/components/NeedsApprovalAlert';
import { FEATURE_TOGGLE } from '@/constants/featuretoggle';
import DashboardFiltersRevamp from '../DashboardFilters/DashboardFiltersRevamp';
import {
  setDashboardFilters,
  selectDashboardFilters,
} from '@/store/slices/filtersSlice';
import { isEqual } from 'lodash';
import useAnalytics from '@/util/analytics';
import { GAEvent } from '@/constants/gaevents';
import ShiftDate from './ShiftGrid/ShiftsDate';
import './index.css';
import ShiftsColumn from './ShiftGrid/ShiftsColumn';
import {
  getInitialStartDate,
  updateUrlParams,
  shortenKeys,
  expandKeys,
} from '@/util/dashboard';
import { debounce } from 'lodash';

const initialFilters: JobFilterSetInput = {
  fillStatus: FillStatusEnum.ALL,
  visibility: VisibilityStatusEnum.ALL,
};

const ShiftWeekList = () => {
  const [loadShiftData, setLoadShiftData] = useState(false);
  const [showSelectedDate, setShowSelectedDate] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedShifts, setSelectedShifts] = useState([]);
  const [bookMarked, setBookMarked] = useState(false);
  const [showNeedsApproval, setShowNeedsApproval] = useState(true);
  const currentTenant = useReactiveVar(currentTenantVar);
  const { logEvent } = useAnalytics();
  const isFeatFlagDisabledCustomerLoginAllowedEnabled = useFeatureValue(
    'disabled-customer-login',
    false,
  );
  const needsApprovalStatus = useFeatureValue(
    FEATURE_TOGGLE.DashboardApprovalStatusFeature,
    false,
  );

  const dashboardFiltersAndPersistView = useFeatureValue(
    FEATURE_TOGGLE.DashboardFiltersAndPersistView,
    false,
  );

  const dashboardMobileFriendlyNavigation = useFeatureValue(
    FEATURE_TOGGLE.DashboardMobileFriendlyNavigation,
    false,
  );

  const currentAdminUser = useReactiveVar(
    currentAdminVar,
  ) as CurrentCustomerAdminFragment;
  const accountDisabled = ls.get(ACCOUNT_DISABLED) === 'true';
  const dispatch = useAppDispatch();
  const refetchData = useAppSelector(selectRefetchDashboard);

  function hasValidAccountStatus() {
    return hasValidCustomerAccountStatus(
      isFeatFlagDisabledCustomerLoginAllowedEnabled,
      currentAdminUser,
      accountDisabled,
    );
  }
  const currentAgency = useReactiveVar(currentAgencyVar);
  const { currentAdmin, isCurrentCustomerAdmin } = useAuth();

  const [startDate, setStartDate] = useState(
    dashboardFiltersAndPersistView
      ? getInitialStartDate(logEvent, currentAgency!.id)
      : MONDAY_OF_WEEK,
  );

  const reduxDashboardFilters = useAppSelector(selectDashboardFilters);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const goBack = useCallback(
    debounce(() => {
      setStartDate((date) => addDays(date, -7));
    }, 200),
    [],
  );

  const goForward = useCallback(
    debounce(() => {
      setStartDate((date) => addDays(date, 7));
    }, 200),
    [],
  );

  const {
    debouncedQuery,
    filters,
    query: searchQuery,
    setQuery,
    setFilter,
    clearAll,
    clearFilter,
  } = useFilters<JobFilterSetInput>(
    isCurrentCustomerAdmin(currentAdmin)
      ? {
          ...initialFilters,
          customers: {
            value: [(currentAdmin as CurrentCustomerAdminFragment).customer.id],
          },
        }
      : initialFilters,
  );

  const { data: filter, refetch: refetchFilters } = useGetUserFilterQuery({
    variables: {
      filterType: UserFilterTypeEnum.DASHBOARD,
    },
  });

  const queryParams = new URLSearchParams(window.location.search);
  const urlFilters = queryParams.get('fts');

  const dashboardFilters = useMemo(() => {
    if (dashboardFiltersAndPersistView) {
      if (isInitialLoad) {
        if (urlFilters) {
          return expandKeys(JSON.parse(decodeURIComponent(urlFilters)));
        } else if (
          reduxDashboardFilters &&
          Object.keys(reduxDashboardFilters).length !== 0
        ) {
          return reduxDashboardFilters;
        } else if (filter?.userFilter) {
          return JSON.parse(
            decodeURIComponent(filter?.userFilter?.filterValue),
          );
        }
      } else {
        return reduxDashboardFilters;
      }
    }
  }, [
    filter,
    urlFilters,
    isInitialLoad,
    reduxDashboardFilters,
    dashboardFiltersAndPersistView,
  ]);

  const sortAndCleanObject = (obj) => {
    if (Array.isArray(obj)) {
      return obj
        .filter((item) => item !== undefined)
        .map(sortAndCleanObject)
        .sort();
    } else if (obj && typeof obj === 'object') {
      return Object.keys(obj)
        .sort()
        .reduce((sortedObj, key) => {
          const value = sortAndCleanObject(obj[key]);
          if (value !== undefined) {
            sortedObj[key] = value;
          }
          return sortedObj;
        }, {});
    }
    return obj;
  };

  useEffect(() => {
    try {
      const keysToFilter = isCurrentCustomerAdmin(currentAdmin)
        ? ['dashboardVisibility', 'regionIds', 'jobDashboardStatus']
        : [];

      const decodedFilterValue = filter?.userFilter?.filterValue
        ? decodeURIComponent(filter.userFilter.filterValue)
        : null;

      let filteredDecodedValue = {};
      if (decodedFilterValue) {
        const parsedDecodedValue = JSON.parse(decodedFilterValue);
        filteredDecodedValue = Object.keys(parsedDecodedValue)
          .filter((key) => !keysToFilter.includes(key))
          .reduce((obj, key) => {
            obj[key] = parsedDecodedValue[key];
            return obj;
          }, {});
      }

      if (
        filteredDecodedValue &&
        Object.keys(filteredDecodedValue).length > 0 &&
        isEqual(
          sortAndCleanObject(dashboardFilters),
          sortAndCleanObject(filteredDecodedValue),
        )
      ) {
        setBookMarked(true);
      }
    } catch (error) {
      console.error('Error parsing or filtering filter value:', error);
    }
  }, [dashboardFilters, filter, currentAdmin]);

  useEffect(() => {
    if (dashboardFiltersAndPersistView) {
      if (isInitialLoad && dashboardFilters) {
        const keysToFilter = isCurrentCustomerAdmin(currentAdmin)
          ? ['dashboardVisibility', 'regionIds', 'jobDashboardStatus']
          : [];

        const filteredDashboardFilters = Object.keys(dashboardFilters)
          .filter((key) => !keysToFilter.includes(key))
          .reduce((obj, key) => {
            obj[key] = dashboardFilters[key];
            return obj;
          }, {});
        dispatch(setDashboardFilters(filteredDashboardFilters));
        setIsInitialLoad(false);
      } else if (!isInitialLoad) {
        updateUrlFilters();
      }
    }
  }, [
    dashboardFilters,
    isInitialLoad,
    dashboardFiltersAndPersistView,
    location.search,
  ]);

  const finalFilters = useMemo<JobFilterSetInput>(() => {
    const value = dashboardFiltersAndPersistView
      ? { ...dashboardFilters }
      : { ...filters };

    if (debouncedQuery !== '' && !dashboardFiltersAndPersistView) {
      value.query = { value: debouncedQuery };
    }

    return value;
  }, [debouncedQuery, filters, dashboardFilters]);

  const startDateISO = useMemo(
    () => formatISO8601(startDate, { representation: 'date' }),
    [startDate],
  );

  useEffect(() => {
    if (dashboardFiltersAndPersistView) {
      updateUrlParams({ sd: startDateISO });
    }
  }, [startDateISO, dashboardFiltersAndPersistView]);

  const { data, loading, refetch } = useListShiftsByWeekQuery({
    variables: {
      agencyId: currentAgency!.id,
      startDate: startDateISO,
      filters: {
        ...finalFilters,
        ...(dashboardFiltersAndPersistView &&
        isCurrentCustomerAdmin(currentAdmin)
          ? { customers: { value: [currentAdmin.customer.id] } }
          : {}),
      },
      timeZone: Intl?.DateTimeFormat()?.resolvedOptions().timeZone,
    },
    pollInterval: TIME_TO_REFRESH,
  });

  const { data: needsApprovalData } = useNeedsApprovalQuery({
    variables: {
      agencyId: currentAgency!.id,
      startDate: formatISO8601(addDays(startDate, -7), {
        representation: 'date',
      }),
      endDate: formatISO8601(addDays(startDate, -1), {
        representation: 'date',
      }),
      tenantId: currentTenant!.id,
      filters: {
        ...finalFilters,
        ...(dashboardFiltersAndPersistView &&
        isCurrentCustomerAdmin(currentAdmin)
          ? { customers: { value: [currentAdmin.customer.id] } }
          : {}),
      },
    },
  });

  const updateUrlFilters = () => {
    updateUrlParams({
      fts: encodeURIComponent(JSON.stringify(shortenKeys(finalFilters))),
    });
  };

  const clearAllFilters = () => {
    clearAll();
    setFilter('fillStatus', FillStatusEnum.ALL);
    setFilter('visibility', VisibilityStatusEnum.ALL);
    if (isCurrentCustomerAdmin(currentAdmin)) {
      setFilter('customers', { value: [currentAdmin.customer.id] });
    }
  };
  useEffect(() => {
    if (refetchData) {
      refetch?.();
      dispatch(setRefetchDashboardData(false));
    }
  }, [refetchData]);

  const [getRegions] = useRegionLazyQuery({
    onCompleted: (data) => {
      if (data) {
        setRegions(data.region);
      }
    },
  });

  useEffect(() => {
    setStartDate(getInitialStartDate(logEvent, currentAgency!.id));
  }, [location.search]);

  useEffect(() => {
    setShowNeedsApproval(true);
    if (!loading) setShowSelectedDate(true);
  }, [data]);

  useEffect(() => {
    getRegions({
      variables: {
        agencyId: currentAgency!.id,
      },
    });
  }, []);

  const dashboardJobFilters = useAppSelector(
    (state) => state.filters.dashboardFilters?.dashboardJobStatus,
  );
  const hasCancelledFilterApplied = () => {
    let cancelledJobFilter;
    if (dashboardFiltersAndPersistView) {
      cancelledJobFilter = dashboardJobFilters?.filter(
        (c) => c === JobStatusEnum.CANCELLED,
      );
    } else {
      cancelledJobFilter = finalFilters?.jobDashboardStatus?.filter(
        (c) => c === JobDashboardStatusEnum.CANCELLED,
      );
    }
    if (cancelledJobFilter !== null && cancelledJobFilter !== undefined) {
      return true;
    }

    return false;
  };

  const getShiftData = () => {
    if (!data) {
      return [];
    }

    if (!hasCancelledFilterApplied()) {
      const d = data?.agency.shiftsByWeek.filter((s) => {
        return s.job.cancelledAt === null || s.job.cancelledAt === undefined;
      });

      return d;
    } else {
      return data.agency.shiftsByWeek;
    }
  };
  const phoneOnly = useMediaQuery('(max-width: 559px)');

  const logNonNavigationEvent = () => {
    if (dashboardFiltersAndPersistView) {
      logEvent(
        GAEvent.DashboardNonCurrentWeekNavigation,
        currentAgency!.id,
        {},
      );
    }
  };

  const setBookmarkAndData = (value) => {
    setBookMarked(value);
    setLoadShiftData(true);
  };

  return (
    <Card noRadius={phoneOnly}>
      <Card.Section>
        <Stack vertical>
          {isCurrentCustomerAdmin(currentAdmin) &&
            needsApprovalStatus &&
            (needsApprovalData?.needsApproval.length || 0) > 0 &&
            showNeedsApproval && (
              <NeedsApprovalAlert
                jobsCount={needsApprovalData?.needsApproval.length || 0}
                viewLastWeek={() => {
                  logEvent(GAEvent.ViewLastWeekPressed, currentAgency!.id, {});
                  goBack();
                }}
                dismiss={() => {
                  logEvent(GAEvent.DismissNeedsApproval, currentAgency!.id, {});
                  setShowNeedsApproval(false);
                }}
                phoneOnly={phoneOnly}
              />
            )}
          <Stack gap={phoneOnly ? 16 : 8} wrap={!!phoneOnly}>
            <Stack justify="apart">
              <Stack gap={16} justify={phoneOnly ? 'apart' : 'start'}>
                <IconicButton
                  a11yLabel="View previous week"
                  appearance={phoneOnly ? 'outline' : 'clear'}
                  icon={faChevronLeft}
                  id="btn_previous_week"
                  size={phoneOnly ? 'md' : 'xs'}
                  onClick={() => {
                    goBack();
                    logNonNavigationEvent();
                  }}
                />
                <Body>
                  {format(startDate, 'MMM do')} &mdash;{' '}
                  {format(addDays(startDate, 6), 'MMM do')}
                </Body>
                <IconicButton
                  a11yLabel="View next week"
                  appearance={phoneOnly ? 'outline' : 'clear'}
                  icon={faChevronRight}
                  id="btn_next_week"
                  size={phoneOnly ? 'md' : 'xs'}
                  onClick={() => {
                    goForward();
                    logNonNavigationEvent();
                  }}
                />
              </Stack>
            </Stack>
            {!dashboardFiltersAndPersistView && (
              <DashboardFilters
                filters={filters}
                query={searchQuery}
                onChangeFilter={setFilter}
                onClearAll={clearAllFilters}
                onClearFilter={clearFilter}
                onQueryChange={setQuery}
              />
            )}
          </Stack>
          {phoneOnly &&
            dashboardMobileFriendlyNavigation &&
            showSelectedDate && (
              <ShiftDate
                startDate={startDate}
                shifts={getShiftData()}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                setSelectedShifts={setSelectedShifts}
                loading={loading}
                loadData={loadShiftData}
                setLoadData={setLoadShiftData}
              />
            )}
          <Stack justify="start" gap="10px">
            {dashboardFiltersAndPersistView && (
              <DashboardFiltersRevamp
                bookMarked={bookMarked}
                setBookMarked={setBookmarkAndData}
                refetch={refetchFilters}
              />
            )}
          </Stack>
          {!dashboardFiltersAndPersistView && hasValidAccountStatus() && (
            <Stack>
              <TagsFilter
                filters={filters}
                onChangeFilter={setFilter}
                onClearAll={clearAllFilters}
                onClearFilter={clearFilter}
              />
            </Stack>
          )}
        </Stack>
        {!hasValidAccountStatus() && (
          <AccountStateError
            message={getAccountErrorDesc(accountDisabled, currentAdminUser)}
            title={getAccountErrorTitle(accountDisabled, currentAdminUser)}
          />
        )}
      </Card.Section>

      {hasValidAccountStatus() &&
        (!phoneOnly || !dashboardMobileFriendlyNavigation) && (
          <ShiftGrid
            loading={loading}
            shifts={getShiftData()}
            startDate={startDate}
          />
        )}
      {phoneOnly && dashboardMobileFriendlyNavigation && (
        <ShiftsColumn date={new Date()} shifts={selectedShifts} />
      )}
    </Card>
  );
};

export default ShiftWeekList;
