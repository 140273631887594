import Button from '@/components/Button';
import Card from '@/components/Card';
import Modal from '@/components/Modal';
import Stack from '@/components/Stack';
import Form from '@/form';
import { GetWorkerQuery } from '@/types/graphql';
import { useWorkerResetVerificationMutation } from '@/graphql';
import { WarningAmberOutlined } from '@mui/icons-material';

import './index.css';

type Props = {
  hideModal: () => void;
  worker: GetWorkerQuery['worker'];
};

const ResetEmploymentEligibilityModal = ({ hideModal, worker }: Props) => {
  const [fetchWorkerResetVerification, { data, loading }] =
    useWorkerResetVerificationMutation();

  return (
    <Modal
      disableClickout
      size="xs"
      title="Allow Reset"
      onRequestClose={hideModal}
    >
      <Card.Section>
        <Form
          className="reset-employment-eligibility-modal-form"
          onSubmit={async () => {
            await fetchWorkerResetVerification({
              variables: { workerId: worker.id },
            });
            hideModal();
          }}
        >
          <div className="reset-employment-eligibility-modal-body">
            <div className="sub-heading">
              <WarningAmberOutlined className="eligibility-unverified-icon" />
              <span className="sub-heading-text">
                This Worker has failed too many attempts of SSN/ITIN validation
              </span>
            </div>
            <div className="sub-heading-message">
              <span> Allow worker to retry validation?</span>
            </div>
          </div>
          <Stack
            className="reset-employment-eligibility-modal-footer"
            justify="end"
          >
            <Button
              a11yLabel="Cancel"
              id="btn-cancel"
              label="Cancel"
              type="reset"
              appearance={'outline'}
              onClick={hideModal}
            />
            <Button
              a11yLabel="Submit"
              id="btn-submit"
              label="Confirm Reset"
              type="submit"
              isLoading={loading}
            />
          </Stack>
        </Form>
      </Card.Section>
    </Modal>
  );
};

export default ResetEmploymentEligibilityModal;
