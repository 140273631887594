import { ChangeEvent, useCallback, useEffect, useState } from 'react';

import Button from '@/components/Button';
import Stack from '@/components/Stack';
import TextSelect from '@/components/TextSelect';
import { FilterProps } from '@/filters';
import useDebouncedValue from '@/hooks/useDebouncedValue';
import { OrderFilterSetInput, OrderStatusEnum } from '@/types/graphql';
import useAnalytics from '@/util/analytics';
import { GAEvent } from '@/constants/gaevents';

const ApprovalStatusFilter = ({
  filters,
  filterKey,
  onChangeFilter,
  onClearFilter,
}: FilterProps<OrderFilterSetInput>) => {
  const { logEvent } = useAnalytics();
  const [value, setValue] = useState<OrderStatusEnum | ''>(
    filters.status ? filters.status.value : '',
  );
  const debouncedValue = useDebouncedValue(value);

  const handleValueChange = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
    logEvent(GAEvent.OrderFilterApprovalStatus, ev.currentTarget.value);
    setValue(ev.currentTarget.value as OrderStatusEnum);
  }, []);

  const clearFilter = useCallback(() => {
    setValue('');
    onClearFilter(filterKey);
  }, [filterKey]);

  useEffect(() => {
    if (debouncedValue !== '') {
      onChangeFilter(filterKey, { inverse: false, value: debouncedValue });
    }
  }, [debouncedValue, filterKey]);

  return (
    <Stack vertical gap={16}>
      <TextSelect
        id="select-approval-status"
        value={value}
        onChange={handleValueChange}
      >
        <option disabled value="">
          Select...
        </option>
        <option value={OrderStatusEnum.NA}>N/A</option>
        <option value={OrderStatusEnum.APPROVED}>All Approved</option>
        <option value={OrderStatusEnum.NO_SHOW}> No Show </option>
        <option value={OrderStatusEnum.IN_PROGRESS}>Checked In</option>
        <option value={OrderStatusEnum.PENDING}>Needs Approval</option>
        <option value={OrderStatusEnum.REJECTED}>Rejected</option>
        <option value={OrderStatusEnum.NO_TIMESHEET}>No Timesheet</option>
        <option value={OrderStatusEnum.TIMESHEET_UNFINISHED}>
          Timesheet Unfinished
        </option>
      </TextSelect>
      {value && (
        <>
          <Button
            a11yLabel="Clear order status filter"
            appearance="plain"
            label="Clear"
            onClick={clearFilter}
          />
        </>
      )}
    </Stack>
  );
};

export default ApprovalStatusFilter;
