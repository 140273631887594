import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@/elements/Button';
import Popover from '@mui/material/Popover';
import { Subheading } from '@/components/Typography';
import Stack from '@/components/Stack';
import { Body } from '@/components/Typography';
import {
  VisibilityStatusEnum,
  JobDashboardStatusEnum,
  Scalars,
} from '@/types/graphql';
import RegionSelect from './RegionsSelect';
import Option from '@/components/Option';
import { useState, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { useEffect, useMemo } from 'react';
import {
  setDashboardVisibility,
  setJobDashboardStatus,
  setDashboardRegionIds,
} from '@/store/slices/filtersSlice';
import useAnalytics from '@/util/analytics';
import { GAEvent } from '@/constants/gaevents';
import useAuth from '@/hooks/useAuth';
import { Select, MenuItem, FormControl } from '@mui/material';
import theme from '@/styles/colors';
import InputLabel from '@mui/material/InputLabel';
import { REGIONS } from '@/constants/filters';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';

const visibilityFilter = [
  {
    label: 'Public',
    value: VisibilityStatusEnum.PUBLIC,
  },
  {
    label: 'Private',
    value: VisibilityStatusEnum.PRIVATE,
  },
  {
    label: 'LTA',
    value: VisibilityStatusEnum.LTA,
  },
  {
    label: 'W2',
    value: VisibilityStatusEnum.W2,
  },
];

const workerStatusFilter = [
  {
    label: 'No show',
    value: JobDashboardStatusEnum.NO_SHOW,
  },
];

const styles = {
  inputLabel: {
    color: theme.themeDefault,
    fontSize: '.875rem',
    fontWeight: 600,
    '&.Mui-focused': {
      color: theme.themeDefault,
      fontWeight: 500,
    },
    transform: 'translate(14px, 10px) scale(1)',
    '&.MuiInputLabel-shrink': {
      color: '#262626',
      fontWeight: 500,
      transform: 'translate(14px, -6px) scale(0.75)',
    },
    transition: 'transform 0.2s ease-in-out, color 0.2s ease-in-out',
  },
};

const AdvancedFilters = ({
  setBookMarked,
}: {
  setBookMarked: (arg: boolean) => void;
}) => {
  const { logEvent } = useAnalytics();
  const { currentAdmin } = useAuth();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const regions = useMemo(() => REGIONS, []);
  const dispatch = useAppDispatch();
  const [selectedVisibility, setSelectedVisibility] = useState<any[]>([]);
  const [selectedJobDashbaordStatus, setSelectedJobDashbaordStatus] =
    useState<any>([]);
  const [selectedRegions, setSelectedRegions] = useState<
    Array<Scalars['ID']['input']>
  >([]);

  const visibilityInStore = useAppSelector(
    (state) => state.filters.dashboardFilters?.dashboardVisibility,
  );

  const jobDashboardStatusInStore = useAppSelector(
    (state) => state.filters.dashboardFilters?.jobDashboardStatus,
  );
  useEffect(() => {
    if (!visibilityInStore?.includes(VisibilityStatusEnum.ALL)) {
      setSelectedVisibility(visibilityInStore || []);
    }
    setSelectedJobDashbaordStatus(jobDashboardStatusInStore || []);
  }, [visibilityInStore, jobDashboardStatusInStore]);

  const dashboardFilters = useAppSelector(
    (state) => state.filters.dashboardFilters,
  );

  const dashboardVisibilitySelected = useCallback(
    (value) => {
      if (selectedVisibility.includes(value)) {
        const updatedVisibility = selectedVisibility.filter(
          (item) => item !== value,
        );
        setSelectedVisibility(updatedVisibility);
      } else {
        const updatedVisibility = [...selectedVisibility, value];
        setSelectedVisibility(updatedVisibility);
      }
    },
    [selectedVisibility, dispatch],
  );

  const jobDashboardStatusSelected = useCallback(
    (value) => {
      if (selectedJobDashbaordStatus.includes(value)) {
        const updatedJobDashboard = selectedJobDashbaordStatus.filter(
          (item) => item !== value,
        );
        setSelectedJobDashbaordStatus(updatedJobDashboard);
      } else {
        const updatedJobDashboard = [...selectedJobDashbaordStatus, value];
        setSelectedJobDashbaordStatus(updatedJobDashboard);
      }
    },
    [selectedJobDashbaordStatus, dispatch],
  );

  const applyFilters = () => {
    if (selectedJobDashbaordStatus.length > 0) {
      dispatch(setJobDashboardStatus(selectedJobDashbaordStatus));
      logEvent(GAEvent.DashboardNoShowFilter, currentAdmin?.user.id);
    } else {
      dispatch(setJobDashboardStatus(undefined));
    }
    if (selectedVisibility.length > 0) {
      dispatch(setDashboardVisibility(selectedVisibility));
      logEvent(GAEvent.DashboardMoreOptionsFilter, currentAdmin?.user.id);
    } else {
      dispatch(setDashboardVisibility(undefined));
    }
    if (selectedRegions.length > 0) {
      dispatch(setDashboardRegionIds(selectedRegions));
      logEvent(GAEvent.DashboardRegionFilter, currentAdmin?.user.id);
    } else {
      dispatch(setDashboardRegionIds(undefined));
    }
    setBookMarked(false);
    handleClose();
  };

  const handleClick = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const getRegionNames = (regionIds) => {
    return regionIds
      .map((id) => regions.find((region) => region.value === id)?.label)
      .filter(Boolean);
  };

  const getVisibilityNames = (visibilityIds) => {
    return visibilityIds
      .map((id) => visibilityFilter.find((item) => item.value === id)?.label)
      .filter(Boolean);
  };

  const getJobDashboardStatusNames = (statusIds) => {
    return statusIds
      .map((id) => workerStatusFilter.find((item) => item.value === id)?.label)
      .filter(Boolean);
  };

  const selectedAdvancedFilters = [
    ...getVisibilityNames(dashboardFilters?.dashboardVisibility || []),
    ...getJobDashboardStatusNames(dashboardFilters?.jobDashboardStatus || []),
    ...getRegionNames(dashboardFilters?.regionIds || []),
  ];

  const renderValue = (selected: string[]) => {
    if (selectedAdvancedFilters.length === 0) {
      return <span>Advanced Filters</span>;
    }

    const displayText = selectedAdvancedFilters[0];
    let additionalCount = selectedAdvancedFilters.length - 1;

    return (
      <Box display="flex" alignItems="center">
        <span>{displayText}</span>
        {additionalCount > 0 && <span>+{additionalCount}</span>}
      </Box>
    );
  };

  return (
    <FormControl style={{ width: '160px' }}>
      <InputLabel id="advance-filters-select-label" sx={styles.inputLabel}>
        Advanced Filters
      </InputLabel>
      <Select
        labelId="advance-filters-select-label"
        label="Advanced Filters"
        open={Boolean(anchorEl)}
        IconComponent={(props) => (
          <FontAwesomeIcon
            style={{ color: theme.themeDefault }}
            icon={Boolean(anchorEl) ? faAngleUp : faAngleDown}
            {...props}
          />
        )}
        onClick={handleClick}
        style={{ borderRadius: '8px' }}
        sx={{
          minWidth: 151,
          maxWidth: 268,
          height: 40,
          color: theme.themeDefault,
          fontSize: '.875rem',
          fontWeight: 600,
          '.MuiOutlinedInput-notchedOutline': {
            borderColor:
              selectedAdvancedFilters.length > 0
                ? '#262626'
                : theme.themeDefault,
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor:
              selectedAdvancedFilters.length > 0
                ? '#262626'
                : theme.themeDefault,
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor:
              selectedAdvancedFilters.length > 0
                ? '#262626'
                : theme.themeDefault,
            borderWidth: '1px',
          },
        }}
        value={selectedAdvancedFilters}
        renderValue={renderValue}
        MenuProps={{ PaperProps: { style: { display: 'none' } } }}
      >
        <MenuItem value="" />
      </Select>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        slotProps={{
          paper: {
            style: { width: '50vh', maxHeight: '48vh' },
          },
        }}
      >
        <Box>
          <Subheading
            css={{
              paddingLeft: '24px',
              paddingTop: '24px',
              paddingBottom: '16px',
            }}
            weight="semibold"
            cellPadding={24}
          >
            Advanced Filters
          </Subheading>

          <Stack
            vertical
            style={{
              padding: '24px',
            }}
          >
            <Body weight="medium">More Options</Body>
            <Stack gap={20} wrap={true} id="dashboardVisibility">
              {visibilityFilter.map((item, index) => (
                <Option
                  key={`dashboardVisibility-${index}`}
                  appearance="checkbox"
                  checked={selectedVisibility?.includes(item.value)}
                  id={`${index}-dashboardVisibility`}
                  label={item.label}
                  type="checkbox"
                  value={item.value}
                  onChange={(ev) =>
                    dashboardVisibilitySelected(ev.target.value)
                  }
                />
              ))}
            </Stack>
          </Stack>
        </Box>

        <Box>
          <Stack
            vertical
            style={{
              padding: '24px',
            }}
          >
            <Body weight="medium">No show</Body>
            <Stack gap={20} wrap={true} id="jobDashboardStatus">
              {workerStatusFilter.map((item, index) => (
                <Option
                  key={`jobDashboardStatus-${index}`}
                  appearance="checkbox"
                  checked={selectedJobDashbaordStatus?.includes(item.value)}
                  id={`${index}-jobDashboardStatus`}
                  label={item.label}
                  type="checkbox"
                  value={item.value}
                  onChange={(ev) => jobDashboardStatusSelected(ev.target.value)}
                />
              ))}
            </Stack>
          </Stack>
        </Box>
        <Box>
          <Stack
            vertical
            style={{
              padding: '24px',
            }}
          >
            <Body weight="medium">Region</Body>
            <RegionSelect
              regions={REGIONS}
              setSelectedRegions={setSelectedRegions}
              selectedRegions={selectedRegions}
            />
          </Stack>
        </Box>
        <Box
          sx={{
            padding: '0 24px 20px 24px',
          }}
        >
          <Button
            onClick={applyFilters}
            style={{ width: '100%', height: '36px' }}
          >
            Apply
          </Button>
        </Box>
      </Popover>
    </FormControl>
  );
};

export default AdvancedFilters;
