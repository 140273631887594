import React, { useState, useMemo, useEffect, useCallback } from 'react';
import {
  Select,
  MenuItem,
  Box,
  Checkbox,
  ListItemText,
  TextField,
  ListSubheader,
  InputAdornment,
  InputLabel,
  FormControl,
  Typography,
  Tooltip,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useGetCustomerAndSkillsQuery } from '@/graphql';
import useAuth from '@/hooks/useAuth';
import {
  CustomerOnboardingStatusEnum,
  GetCustomerAndSkillsQuery,
} from '@/types/graphql';
import theme from '@/styles/colors';
import { SelectChangeEvent } from '@mui/material';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { setDashboardCustomerIds } from '@/store/slices/filtersSlice';
import Avatar from '@/components/Avatar';
import useAnalytics from '@/util/analytics';
import { GAEvent } from '@/constants/gaevents';
import { OptionItem } from './OptionsModal';
import { debounce } from 'lodash';
import { getDisplayText } from './helpers';
import { FixedSizeList } from 'react-window';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';

type Item = GetCustomerAndSkillsQuery['agency']['customers']['items'][0];

const styles = {
  inputLabel: {
    color: theme.themeDefault,
    fontSize: '.875rem',
    fontWeight: 600,
    '&.Mui-focused': {
      color: theme.themeDefault,
      fontWeight: 500,
    },
    transform: 'translate(14px, 10px) scale(1)',
    '&.MuiInputLabel-shrink': {
      color: '#262626',
      fontWeight: 500,
      transform: 'translate(14px, -6px) scale(0.75)',
    },
    transition: 'transform 0.2s ease-in-out, color 0.2s ease-in-out',
  },
  menuPaper: {
    maxHeight: '544px',
    minWidth: '268px',
  },
  checkbox: {
    borderRadius: '20px',
    '&.Mui-checked': {
      color: theme.themeDefault,
    },
  },
  menuItem: {
    fontSize: '.875rem',
    fontWeight: 600,
  },
};

const ClientsSelect = ({
  setBookMarked,
  showMobileModal,
  phoneOnly,
}: {
  setBookMarked: (arg: boolean) => void;
  showMobileModal: (
    title: string,
    items: OptionItem[],
    selectedItems: string[],
    showFilter: boolean,
    updateFilters: (selectedItem: string[]) => void,
    selectAll?: boolean,
  ) => void;
  phoneOnly: boolean;
}) => {
  const { logEvent } = useAnalytics();
  const { currentAgency, currentAdmin } = useAuth();
  const dispatch = useAppDispatch();
  const { data } = useGetCustomerAndSkillsQuery({
    variables: {
      agencyId: currentAgency!.id,
      filters: {
        status: [1],
        onboardingStatus: [CustomerOnboardingStatusEnum.COMPLETED],
      },
    },
  });

  const items: Item[] = useMemo(() => {
    if (data) {
      return data.agency.customers.items;
    }
    return [];
  }, [data]);

  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [open, setOpen] = useState(false);

  const filteredCustomers = useMemo(() => {
    return items.filter((customer) =>
      customer.name.toLowerCase().includes(searchTerm.toLowerCase()),
    );
  }, [items, searchTerm]);

  const customerIdsInStore = useAppSelector(
    (state) => state.filters.dashboardFilters?.customerIds,
  );
  useEffect(() => {
    setSelectedItems((customerIdsInStore as string[]) || []);
  }, [customerIdsInStore]);

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const value = event.target.value as string[];
    if (value.includes('all')) {
      if (selectedItems.length === filteredCustomers.length) {
        setSelectedItems([]);
        dispatch(setDashboardCustomerIds(undefined));
      } else {
        setSelectedItems(filteredCustomers.map((item) => item.id));
        dispatch(
          setDashboardCustomerIds(filteredCustomers.map((item) => item.id)),
        );
      }
    } else {
      setSelectedItems(value);
      dispatch(setDashboardCustomerIds(value.length > 0 ? value : undefined));
    }
    setBookMarked(false);
    logEvent(GAEvent.DashbaordClientFilter, currentAdmin?.user.id);
  };

  const renderValue = (selected: string[]) => {
    if (selected.length === 0) {
      return <span>Client</span>;
    }

    const selectedNames = selected
      .map((id) => items.find((item) => item.id === id)?.name)
      .filter(Boolean);

    if (selectedNames.length === 0) {
      return <span>Client</span>;
    }

    return (
      <Box display="flex" alignItems="center">
        <span>{getDisplayText(selectedNames)}</span>
      </Box>
    );
  };

  const handleSearchChange = useCallback(
    debounce((value) => setSearchTerm(value), 300),
    [],
  );

  const Row = ({
    index,
    style,
  }: {
    index: number;
    style: React.CSSProperties;
  }) => {
    if (index === 0) {
      return (
        <MenuItem
          disableRipple
          style={{ cursor: 'default', pointerEvents: 'none', ...style }}
        >
          <Typography
            variant="h6"
            style={{
              fontSize: '.875rem',
              fontWeight: 'bold',
              color: '#000',
              opacity: 100,
              marginBottom: '5px',
            }}
          >
            Clients
          </Typography>
        </MenuItem>
      );
    }

    if (index === 1) {
      return (
        <ListSubheader style={style}>
          <TextField
            size="small"
            autoFocus
            placeholder="Type to search..."
            fullWidth
            value={searchTerm}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              handleSearchChange(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key !== 'Escape') {
                e.stopPropagation();
              }
            }}
          />
        </ListSubheader>
      );
    }

    const handleSelectAllClick = () => {
      if (selectedItems.length === filteredCustomers.length) {
        setSelectedItems([]);
        dispatch(setDashboardCustomerIds(undefined));
      } else {
        setSelectedItems(filteredCustomers.map((item) => item.id));
        dispatch(
          setDashboardCustomerIds(filteredCustomers.map((item) => item.id)),
        );
      }
      setBookMarked(false);
      logEvent(GAEvent.DashbaordClientFilter, currentAdmin?.user.id);
    };

    if (index === 2) {
      return (
        <MenuItem value="all" style={style} onClick={handleSelectAllClick}>
          <Checkbox
            checked={
              filteredCustomers.length > 0 &&
              filteredCustomers.every((customer) =>
                selectedItems.includes(customer.id),
              )
            }
            sx={styles.checkbox}
          />
          <ListItemText
            primary="Select All"
            primaryTypographyProps={styles.menuItem}
          />
        </MenuItem>
      );
    }

    const customer = filteredCustomers[index - 3];
    if (!customer) return null;

    const handleItemClick = () => {
      let updatedSelectedItems;
      if (selectedItems.includes(customer.id)) {
        updatedSelectedItems = selectedItems.filter((id) => id !== customer.id);
      } else {
        updatedSelectedItems = [...selectedItems, customer.id];
      }
      setSelectedItems(updatedSelectedItems);
      dispatch(
        setDashboardCustomerIds(
          updatedSelectedItems.length > 0 ? updatedSelectedItems : undefined,
        ),
      );
      setBookMarked(false);
      logEvent(GAEvent.DashbaordClientFilter, currentAdmin?.user.id);
    };

    return (
      <MenuItem
        key={customer.id}
        value={customer.id}
        style={style}
        onClick={handleItemClick}
      >
        <Checkbox
          checked={selectedItems.includes(customer.id)}
          sx={styles.checkbox}
        />
        <Avatar
          firstName={customer.name}
          css={{ height: '32px', width: '32px', marginRight: '8px' }}
          src={customer.logoUrl}
        />
        <Tooltip title={customer.name} placement="right">
          <ListItemText
            primary={customer.name}
            primaryTypographyProps={styles.menuItem}
          />
        </Tooltip>
      </MenuItem>
    );
  };

  return (
    <FormControl style={{ maxWidth: '150px', marginRight: '10px' }}>
      <InputLabel id="clients-multi-select-label" sx={styles.inputLabel}>
        Client
      </InputLabel>
      <Select
        labelId="clients-multi-select-label"
        label="Client"
        multiple
        value={selectedItems}
        renderValue={renderValue}
        fullWidth
        style={{ borderRadius: '8px' }}
        sx={{
          minWidth: 90,
          maxWidth: 268,
          height: 40,
          color: theme.themeDefault,
          fontSize: '.875rem',
          fontWeight: 600,
          '.MuiOutlinedInput-notchedOutline': {
            borderColor:
              selectedItems.length > 0 ? '#262626' : theme.themeDefault,
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor:
              selectedItems.length > 0 ? '#262626' : theme.themeDefault,
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor:
              selectedItems.length > 0 ? '#262626' : theme.themeDefault,
            borderWidth: '1px',
          },
        }}
        open={open}
        onOpen={() => {
          phoneOnly
            ? showMobileModal(
                'Clients',
                items,
                selectedItems,
                true,
                (selected: string[]) => {
                  handleChange({
                    target: { value: selected } as SelectChangeEvent<string[]>,
                  });
                },
                true,
              )
            : setOpen(true);
        }}
        IconComponent={(props) => (
          <FontAwesomeIcon
            style={{ color: theme.themeDefault }}
            icon={open ? faAngleUp : faAngleDown}
            {...props}
          />
        )}
        onClose={() => {
          setOpen(false);
          setTimeout(() => {
            setSearchTerm('');
          }, 150);
        }}
        MenuProps={{
          autoFocus: false,
          PaperProps: {
            style: styles.menuPaper,
          },
        }}
      >
        <FixedSizeList
          itemCount={filteredCustomers.length + 3}
          itemSize={40}
          height={Math.min((filteredCustomers.length + 3) * 50, 500)}
          width="100%"
          style={{
            maxHeight: styles.menuPaper.maxHeight,
            minWidth: styles.menuPaper.minWidth,
            overflowX: 'hidden',
          }}
        >
          {Row}
        </FixedSizeList>
      </Select>
    </FormControl>
  );
};

export default ClientsSelect;
