import { SubmitHelpers } from '@area2k/use-form';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';

import { SelectItem } from '..';
import { JobStatus, getJobStatusByJob } from '../../Job/util';

import Button from '@/components/Button';
import Card from '@/components/Card';
import ConfirmationModal from '@/components/ConfirmationModal';
import IconicButton from '@/components/IconicButton';
import LtaWorkersList from '@/components/LtaWorkersList';
import Modal from '@/components/Modal';
import Stack from '@/components/Stack';
import { Body, Small } from '@/components/Typography';
import WorkerAutocompleteField, {
  WorkerItem,
} from '@/components/WorkerAutocompleteField';
import Form from '@/form';
import { useJobHireWorkerMutation, useJobOfferCreateMutation } from '@/graphql';
import useAuth from '@/hooks/useAuth';
import {
  GetOrderQuery,
  JobTypeEnum,
  RelationshipKindEnum,
  Scalars,
  TaxTypeEnum,
} from '@/types/graphql';
import { ErrorMap, handleMutationFormError } from '@/util/error';
import { workerName } from '@/util/worker';

export const JOB_HIRE_AND_OFFER_ERROR_MAP: ErrorMap = {
  ALREADY_HIRED: () => ({
    title: 'GravyWorker already hired',
    message: 'This GravyWorker has already been hired for this job.',
  }),
  ALREADY_OFFERED: () => ({
    title: 'Offer already sent',
    message: 'This GravyWorker has already been offered this job.',
  }),
  INVALID_JURISDICTION: () => ({
    title: "Gravyworker's onboarding isn't complete",
    message:
      '{0} needs to complete prerequisites to be hired. Please invite them instead.',
  }),
  OVERLAPPING_HOURS: (gqlError) => ({
    title: 'Overlapping hours',
    message: gqlError.message,
  }),
  WORKER_ONBOARDING_NOT_COMPLETED: () => ({
    title: "Gravyworker's onboarding isn't complete",
    message:
      '{0} needs to complete prerequisites to be hired. Please invite them instead.',
  }),
  WORKER_REQUIRED_SKILL_MISSING: () => ({
    title: `This GravyWorker doesn't have the requested skill`,
    message: 'Verify that the GravyWorker is able to work in this position.',
  }),
  JOB_UNAVAILABLE: () => ({
    title: 'Error',
    message:
      'Another GravyWorker has already been hired for this job. You can no longer hire it to GravyWorkers.',
    status: 'danger',
  }),
  WORKER_ALREADY_DROPPED_JOB: (gqlError) => ({
    title: 'Error',
    message: gqlError.message,
  }),
  JOB_FILLED: () => ({
    title: 'Job already filled',
    message:
      'Another GravyWorker has already been hired for this job. You can no longer offer it to GravyWorkers.',
    status: 'danger',
  }),
  JOB_IN_PAST: () => ({
    title: 'Error',
    message: 'Cannot invite workers for the past Jobs.',
  }),
  WORKER_OFFER_REJECTED: (gqlError) => ({
    title: 'Error',
    message: gqlError.message,
    status: 'danger',
  }),
  WORKER_IS_BLOCKED: () => ({
    title: 'Error',
    message: 'GravyWorker is blocked for this client account',
    status: 'danger',
  }),
};

type FormValues = {
  worker: WorkerItem | null;
};

type Props = {
  hideModal: () => Scalars['Void'];
  onConfirm: () => Scalars['Void'];
  deleteItem: (id: Scalars['String']) => Scalars['Void'];
  selectedJobs: SelectItem[];
  customer: GetOrderQuery['order']['account']['customer'];
  context: 'hire' | 'invite';
  hasJobsForInvite?: boolean;
  address: any;
};

const InviteAndHireModal = ({
  hideModal,
  onConfirm,
  deleteItem,
  selectedJobs,
  context,
  customer,
  hasJobsForInvite = true,
  address,
}: Props) => {
  const { id: customerId, jobTaxType } = customer;
  const [errorjobs, setErrorJobs] = useState<any>([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [rehireWorkerId, setRehireWorkerId] = useState('');
  const { currentAdminIsCustomerAdmin } = useAuth();

  const skillsSelected = [
    ...new Set(selectedJobs.map((item) => item.skill.id)),
  ] as [string];
  const jurisdictionSelected = [
    ...new Set(selectedJobs.map((item) => item.jurisdiction)),
  ] as [string];

  const initialValues: FormValues = {
    worker: null,
  };
  const isLtaJob =
    selectedJobs?.length > 0
      ? selectedJobs[0].jobType === JobTypeEnum.LTA
      : false;

  const [createJobOffer, { loading: inviteLoading }] =
    useJobOfferCreateMutation();

  const [hireWorker, { loading: hireLoading }] = useJobHireWorkerMutation({
    refetchQueries: ['GetOrder'],
  });

  const handleSubmit = async (
    values: FormValues,
    { setFormError }: SubmitHelpers,
  ): Promise<void> => {
    const workerId = values.worker!.id;
    const rehireJobs: any = [];
    let hasError = false;

    for (let i = 0; i < selectedJobs.length; i++) {
      const job = selectedJobs[i];
      const jobId = job.id;
      try {
        if (context === 'invite') {
          const jobStatus = getJobStatusByJob(job);
          if (jobStatus === JobStatus.UPCOMING) {
            await createJobOffer({
              variables: { workerId, jobId, forceInvite: null },
            });
          }
        } else {
          await hireWorker({
            variables: { workerId, jobId, payRate: null, forceHire: null },
          });
        }
      } catch (err: any) {
        hasError = true;
        if (
          err?.graphQLErrors &&
          !currentAdminIsCustomerAdmin &&
          (err.graphQLErrors[0]?.extensions?.code ===
            'WORKER_ALREADY_DROPPED_JOB' ||
            err.graphQLErrors[0]?.extensions?.code === 'ALREADY_OFFERED')
        ) {
          setRehireWorkerId(workerId);
          rehireJobs.push(job);
        } else {
          handleMutationFormError(
            err,
            {
              setFormError,
              errorMap: JOB_HIRE_AND_OFFER_ERROR_MAP,
            },
            workerName(values.worker),
          );
        }
      }
    }

    if (rehireJobs.length > 0) {
      setErrorJobs(rehireJobs);
      setShowConfirmModal(true);
    } else if (!hasError) {
      onConfirm();
      hideModal();
    }
  };

  const jobslabels = errorjobs.map((e) => e.label)?.join(', ');

  const handleRehire = async () => {
    for (let i = 0; i < errorjobs.length; i++) {
      const workerId = rehireWorkerId;
      const jobId = errorjobs[i].id;
      try {
        if (context === 'invite') {
          await createJobOffer({
            variables: { workerId, jobId, forceInvite: true },
          });
        } else {
          await hireWorker({
            variables: { workerId, jobId, payRate: null, forceHire: true },
          });
        }
      } catch (e) {
        console.error(e);
      }
    }
    onConfirm();
    hideModal();
  };

  const showError =
    selectedJobs.length < 1 || (context === 'invite' && !hasJobsForInvite);

  return (
    <>
      <Modal
        disableClickout
        size="sm"
        title={
          context === 'hire'
            ? 'Hire Gravyworker for this job'
            : 'Offer job to GravyWorker'
        }
        onRequestClose={hideModal}
      >
        <Card.Section>
          {showError ? (
            <>
              <Body>
                {context === 'invite' && !hasJobsForInvite
                  ? `Please select at least one upcoming job to ${context}.`
                  : `Please select at least one job to ${context}.`}
              </Body>
              <Stack justify="end">
                <Button a11yLabel="Ok" label="Ok" onClick={hideModal} />
              </Stack>
            </>
          ) : (
            <Stack vertical gap={16}>
              <Stack vertical>
                <Body>Jobs Selected ({selectedJobs.length})</Body>
                <Stack vertical>
                  {selectedJobs.map((job, index) => (
                    <Stack key={index} gap={0}>
                      <IconicButton
                        a11yLabel="delete item"
                        appearance="clear"
                        icon={faTimes}
                        iconTheme={'lighter'}
                        size="xs"
                        type="button"
                        onClick={() => deleteItem(job.id)}
                      />
                      <Small key={job.id}>{job.label}</Small>
                    </Stack>
                  ))}
                </Stack>
              </Stack>

              <Stack>
                <Form
                  initialValues={initialValues}
                  style={{ width: '100%' }}
                  onSubmit={handleSubmit}
                >
                  {context === 'hire' && isLtaJob ? (
                    <LtaWorkersList
                      address={address}
                      customerId={customerId}
                      skillIds={skillsSelected}
                    />
                  ) : (
                    <WorkerAutocompleteField
                      context="multipleJobSelection"
                      fieldId="worker"
                      jurisdiction={jurisdictionSelected}
                      label="Select GravyWorker"
                      relationShip={{
                        customerId,
                        kind: RelationshipKindEnum.BLOCKED,
                      }}
                      skillsId={skillsSelected}
                      {...(jobTaxType === 'w2' && {
                        taxType: TaxTypeEnum.TAX_W2,
                      })}
                    />
                  )}

                  <Stack justify="end">
                    <Button
                      a11yLabel="Submit form"
                      isLoading={
                        context === 'hire' ? hireLoading : inviteLoading
                      }
                      label={context === 'hire' ? 'Hire' : 'Invite'}
                      type="submit"
                    />
                  </Stack>
                </Form>
              </Stack>
            </Stack>
          )}
        </Card.Section>
      </Modal>
      {showConfirmModal && (
        <ConfirmationModal
          acceptAction={handleRehire}
          acceptButtonLabel={context === 'hire' ? 'Hire worker' : 'Send Invite'}
          bodyContentText={
            context === 'hire'
              ? `This worker has previously dropped the jobs on : ${jobslabels}. Do you want to proceed?`
              : `  'This worker has previously declined an invitation for the jobs on : ${jobslabels}. Do you want to proceed?'`
          }
          denyAction={() => {}}
          denyButtonLabel="Cancel"
          hideModal={() => setShowConfirmModal(false)}
          title={`Confirmation`}
        />
      )}
    </>
  );
};

export default InviteAndHireModal;
