import { useReactiveVar } from '@apollo/client';
import { SubmitHelpers } from '@area2k/use-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Navigate, useNavigate } from 'react-router-dom';

import { Role } from '../PrivateRoute';

import setPasswordSchema from './schema';

import Button from '@/components/Button';
import Card from '@/components/Card';
import FormElement from '@/components/FormElement';
import Option from '@/components/Option';
import Page from '@/components/Page';
import Stack from '@/components/Stack';
import TextInput from '@/components/TextInput';
import TextStack from '@/components/TextStack';
import { Body, Display, Small } from '@/components/Typography';
import Version from '@/components/Version';
import Link from '@/elements/Link';
import Form from '@/form';
import FormFooter from '@/form/FormFooter';
import {
  useResetPasswordMutation,
  useUserSetPasswordMutation,
} from '@/graphql';
import useLocationSearch from '@/hooks/useLocationSearch';
import useQueryString from '@/hooks/useQueryString';
import { currentTenantVar, currentAdminVar } from '@/util/apollo/cache';
import { handleMutationFormError } from '@/util/error';
import { openInNewTab } from '@/util/link';
import ls, { DEVICE_TOKEN_KEY, RESET_PASSWORD } from '@/util/localstorage';
import { isWeb } from '@/util/platform';

import routes from '../routes';

type FormValues = { password: string; confirmPassword: string };

const initialValues: FormValues = { password: '', confirmPassword: '' };

const ResetPassword = () => {
  const [onSend, setOnSend] = useState<boolean>(false);
  const { to } = useLocationSearch();
  const query = useQueryString();

  const token = query.get('token') as string;

  const deviceToken = ls.get(DEVICE_TOKEN_KEY);
  const resetPassword = ls.get(RESET_PASSWORD);

  const destination = useMemo(() => (typeof to === 'string' ? to : '/'), [to]);

  const currentAdmin = useReactiveVar(currentAdminVar);
  const currentTenant = useReactiveVar(currentTenantVar);
  const [userResetPassword, { loading: resetLoading }] =
    useResetPasswordMutation();
  const [setFirstPassword, { loading: setLoading }] =
    useUserSetPasswordMutation({
      onCompleted: () => {
        if (currentAdmin && currentAdmin.role !== Role.TENANT_ADMIN) {
          return openInNewTab(
            'https://gravywork.com/client-services-agreement/',
          );
        } else {
          return null;
        }
      },
    });
  const navigate = useNavigate();

  const [
    agreeWithClientServicerAgreement,
    setAgreeWithClientServicerAgreement,
  ] = useState<boolean>(false);

  const {
    formState: { errors, isValid },
    getValues,
    register,
    setValue,
  } = useForm<FormValues>({
    resolver: yupResolver(setPasswordSchema),
    mode: 'onChange',
    defaultValues: initialValues,
  });

  const handleFormValuesChange = (
    fieldValue: string,
    fieldId: keyof FormValues,
  ) => {
    setValue(fieldId, fieldValue);
  };

  const { password, confirmPassword } = getValues();

  const ClientServicerAgreement = useMemo(
    () => (
      <Stack inline wrap gap={4}>
        <span>I agree with</span>
        <Button
          a11yLabel="Open new tab to Client Services Agreement"
          appearance="plain"
          label="Client Services Agreement"
          type="button"
          onClick={() =>
            openInNewTab('https://gravywork.com/client-services-agreement/')
          }
        />
      </Stack>
    ),
    [],
  );

  const handleForgotPassword = useCallback(
    async (values: FormValues, { setFormError }: SubmitHelpers) => {
      try {
        await userResetPassword({
          variables: { token, password },
        });
        setOnSend(true);
      } catch (err) {
        handleMutationFormError(err, {
          setFormError,
          errorMap: {
            TOKEN_INVALID: () => ({
              title: 'Your password reset link has expired',
              message: `For security reasons, password reset links expire after a certain amount of time. Don't worry—you can request a new link to reset your password. Click "Send me a new link" below to start the process again.`,
              status: 'warning',
            }),
            all: (gqlError) => ({
              title: gqlError.name,
              message: gqlError.message,
              status: 'danger',
            }),
          },
        });
      }
    },
    [currentTenant, password, token],
  );

  const handleFirstPassword = useCallback(
    async (values: FormValues, { setFormError }: SubmitHelpers) => {
      try {
        await setFirstPassword({
          variables: {
            password,
            repeatedPassword: confirmPassword,
          },
        });
        ls.set(RESET_PASSWORD, 'false');
        navigate(destination);
      } catch (err) {
        handleMutationFormError(err, {
          setFormError,
          errorMap: {
            all: (gqlError) => ({
              title: gqlError.name,
              message: gqlError.message,
              status: 'danger',
            }),
          },
        });
      }
    },
    [confirmPassword, currentTenant, password],
  );

  const handleSubmit =
    resetPassword === 'true' ? handleFirstPassword : handleForgotPassword;

  if (deviceToken && resetPassword !== 'true') {
    return <Navigate replace to={destination} />;
  }

  return (
    <Page size="xs">
      <div style={{ padding: '64px 0' }}>
        <Stack vertical gap={24}>
          <div
            style={{
              padding: '0 12.5% 16px',
              width: '100%',
              textAlign: 'center',
            }}
          >
            <Display>{currentTenant?.name}</Display>
          </div>
          {onSend ? (
            <Card>
              <Card.Section>
                <Stack vertical verticalGap={25}>
                  <TextStack>
                    <Body weight="bold">
                      Your password was successfully created!
                    </Body>
                  </TextStack>
                  <Stack justify="end">
                    <Link to={isWeb() ? '/login' : '/app-login'}>
                      <Button
                        a11yLabel="Sign In"
                        id="sign-in-btn"
                        type="button"
                      />
                    </Link>
                  </Stack>
                </Stack>
              </Card.Section>
            </Card>
          ) : (
            <Card title="Set new password">
              <Card.Section>
                <Stack vertical verticalGap={25}>
                  <Form
                    initialValues={initialValues}
                    style={{ width: '100%' }}
                    onSubmit={handleSubmit}
                  >
                    <FormElement htmlFor="password" label="New password">
                      <TextInput
                        {...register('password', {
                          onChange: (ev) =>
                            handleFormValuesChange(
                              ev.currentTarget.value,
                              'password',
                            ),
                        })}
                        required
                        css={{ letterSpacing: '2px' }}
                        id="password"
                        placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                        type="password"
                      />
                      {errors.password && (
                        <Small color="danger">{errors.password.message}</Small>
                      )}
                    </FormElement>

                    <FormElement
                      htmlFor="confirmPassword"
                      label="Confirm new password"
                    >
                      <TextInput
                        {...register('confirmPassword', {
                          onChange: (ev) =>
                            handleFormValuesChange(
                              ev.currentTarget.value,
                              'confirmPassword',
                            ),
                        })}
                        required
                        css={{ letterSpacing: '2px' }}
                        id="confirmPassword"
                        placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                        type="password"
                      />
                      {errors.confirmPassword && (
                        <Small color="danger">
                          {errors.confirmPassword.message}
                        </Small>
                      )}
                    </FormElement>
                    {resetPassword === 'true' &&
                      currentAdmin &&
                      currentAdmin.role !== Role.TENANT_ADMIN && (
                        <FormElement htmlFor="clientServicesAgreement">
                          <Option
                            appearance="checkbox"
                            checked={agreeWithClientServicerAgreement}
                            id="clientServicesAgreement"
                            label={ClientServicerAgreement}
                            type="checkbox"
                            onChange={() =>
                              setAgreeWithClientServicerAgreement(
                                (prevValue) => !prevValue,
                              )
                            }
                          />
                        </FormElement>
                      )}
                    <FormFooter>
                      <Link
                        id="forget-password"
                        to={`/${routes.forgotPassword}`}
                      >
                        Send me a new link
                      </Link>
                      <Button
                        a11yLabel="Confirm"
                        disabled={
                          !isValid ||
                          (resetPassword === 'true' &&
                            !agreeWithClientServicerAgreement &&
                            currentAdmin &&
                            currentAdmin.role !== Role.TENANT_ADMIN)
                        }
                        id="confirm-btn"
                        isLoading={resetLoading || setLoading}
                        type="submit"
                      />
                    </FormFooter>
                  </Form>
                </Stack>
              </Card.Section>
            </Card>
          )}
          <div style={{ width: '100%', textAlign: 'center' }}>
            <Version size="sm" />
          </div>
        </Stack>
      </div>
    </Page>
  );
};

export default ResetPassword;
