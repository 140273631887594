import useModal from '@area2k/use-modal';
import { useFeatureValue } from '@growthbook/growthbook-react';
import { differenceInHours, format, max, min, parseISO } from 'date-fns';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import CancellationDissmissModal from '../Job/modals/CancellationAndDismissModal';
import ConfirmModalCancellation from '../Job/modals/ConfirmModalCancellation';
import { getJobStatusByJob, JobStatus } from '../Job/util';

import ClientCard from './CustomerCard';
import InviteAndHireModal from './InviteAndHireModal';
import OrderJobTableItem from './OrderJobTableItem';
import PageSkeleton from './PageSkeleton';
import SummaryCard from './SummaryCard';
import { disableCheckbox, getTaxTypeLabel, isJobCompOrCancel } from './util';

import Badge from '@/components/Badge';
import Button from '@/components/Button';
import Card from '@/components/Card';
import Option from '@/components/Option';
import Page from '@/components/Page';
import PrimarySecondaryLayout from '@/components/PrimarySecondaryLayout';
import QueryEmptyState from '@/components/QueryEmptyState';
import Stack from '@/components/Stack';
import Table from '@/components/Table';
import TableHeader from '@/components/TableHeader';
import { Title } from '@/components/Typography';
import WorkerStaffingModel from '@/components/WorkerStaffingModel';
import { TIME_TO_REFRESH } from '@/constants/general';
import {
  ContextCancelRemoveModal,
  ContextConfirmModalCancellation,
} from '@/constants/job';
import { TableHeading } from '@/elements/Table';
import { useGetOrderQuery } from '@/graphql';
import useAuth from '@/hooks/useAuth';
import useMediaQuery from '@/hooks/useMediaQuery';
import PermissionComponent from '@/routes/PermissionComponent';
import { GetOrderQuery, JobTypeEnum } from '@/types/graphql';
import { DAY_HOURS } from '@/util/constants';
import { formatISO, getTimezoneAbbreviation } from '@/util/date';
import { getHiredWorkersByJobWorkers } from '@/util/job';
import styled from '@/styles';
import TZBadge from '@/components/TZBadge';

export type JobItem = GetOrderQuery['order']['jobs'][0];
export type Customer = GetOrderQuery['order']['account']['customer'];

export interface SelectItem extends JobItem {
  jurisdiction: JobItem['address']['state'];
  label: string;
}

const getOrderDetailSubtitle = (order: GetOrderQuery['order']) => {
  const onlyFirstShiftsStartAtByJob = order.jobs.flatMap(
    (job) => new Date(job.firstShiftStartAt),
  );
  const onlyLastShiftsEndAtByJob = order.jobs.flatMap(
    (job) => new Date(job.lastShiftEndAt),
  );

  const orderFromDate = min(onlyFirstShiftsStartAtByJob).toISOString();
  const orderToDate = max(onlyLastShiftsEndAtByJob).toISOString();
  const formatOrderStartAt = formatISO(
    orderFromDate,
    'PP',
    order.jobs[0].address.timezone,
  );
  const formatOrderEndAt = formatISO(
    orderToDate,
    'PP',
    order.jobs[0].address.timezone,
  );
  const subtitle = `${formatOrderStartAt} — ${formatOrderEndAt}`;

  return subtitle;
};

const Order = () => {
  const { orderId } = useParams();
  const { currentAdminIsCustomerAdmin } = useAuth();
  const [selecteds, setSelecteds] = useState<SelectItem[] | null>(null);
  const [showStaffingModel, setShowStaffModel] = useState(false);

  const phoneOnly = useMediaQuery('(max-width: 559px)');
  const query = useGetOrderQuery({
    variables: { orderId },
    pollInterval: TIME_TO_REFRESH,
    fetchPolicy: 'network-only',
  });

  const [showCancelOrderModal, hideCancelOrderModal] = useModal(
    ({ order }: { order: GetOrderQuery['order'] }) => (
      <CancellationDissmissModal
        context={ContextCancelRemoveModal.CancelOrder}
        hideModal={() => {
          hideConfirmCancelOrder();
          hideCancelOrderModal();
        }}
        order={order}
      />
    ),
    [],
  );

  const [confirmCancelOrder, hideConfirmCancelOrder] = useModal(
    ({ order }: { order: GetOrderQuery['order'] }) => (
      <ConfirmModalCancellation
        context={ContextConfirmModalCancellation.CancelOrder}
        hideModal={hideConfirmCancelOrder}
        onAction={() => {
          showCancelOrderModal({ order });
        }}
      />
    ),
    [],
  );

  const onCancelPress = (order: GetOrderQuery['order']) => {
    const someUpcomingJobStartInLess24Hours = order.jobs.some(
      (job) =>
        differenceInHours(parseISO(job.firstShiftStartAt), new Date()) <
          DAY_HOURS && getJobStatusByJob(job) === JobStatus.UPCOMING,
    );

    if (someUpcomingJobStartInLess24Hours && currentAdminIsCustomerAdmin) {
      return confirmCancelOrder({ order });
    }

    return showCancelOrderModal({ order });
  };

  const [showActionModal, HideActionModal] = useModal<{
    context: 'hire' | 'invite';
    customer: Customer;
  }>(
    ({ context, customer }) => {
      let hasJobsForInvite = false;
      if (selecteds?.length && context === 'invite') {
        selecteds.every((s) => {
          const jobStatus = getJobStatusByJob(s);
          if (jobStatus === JobStatus.UPCOMING) {
            hasJobsForInvite = true;
            return false;
          }
          return true;
        });
      }

      return (
        <InviteAndHireModal
          address={order?.jobs?.length > 0 ? order.jobs[0].address : null}
          context={context}
          customer={customer}
          deleteItem={deleteItemFromSelecteds}
          hasJobsForInvite={hasJobsForInvite}
          hideModal={HideActionModal}
          selectedJobs={selecteds ?? []}
          onConfirm={() => {
            selecteds && setSelecteds([]);
          }}
        />
      );
    },
    [selecteds],
  );

  const handleSelect = (selectedItem: SelectItem) => {
    setSelecteds((prevValues) => {
      if (!prevValues) {
        return [selectedItem];
      }

      const valueAlreadyExists = prevValues.some(
        (item) => item.id === selectedItem.id,
      );

      if (valueAlreadyExists) {
        return prevValues.filter((item) => item.id !== selectedItem.id);
      }

      return [...prevValues, selectedItem];
    });
  };

  const deleteItemFromSelecteds = (id: string) => {
    if (selecteds) {
      const newSelecteds = selecteds.filter((item) => item.id !== id);
      setSelecteds(newSelecteds);
    }
  };

  if (!query.data) {
    return <PageSkeleton />;
  }

  const order = query.data.order;
  const { account } = order;
  const { customer } = account;
  const subtitle = getOrderDetailSubtitle(order);
  const { 0: firstJob } = order.jobs;
  const { taxType } = firstJob;
  const taxTypeLabel = getTaxTypeLabel(taxType);
  const actionButton =
    order.cancelledAt === null
      ? {
          a11yLabel: 'Cancel this order',
          label: 'Cancel order',
          dangerous: true,
          id: 'cancel-order-btn',
          onAction: () => onCancelPress(order),
        }
      : undefined;

  const validSelectableJobs = order?.jobs.filter((job) => {
    const jobStatus = getJobStatusByJob(job);
    const isDisabled = disableCheckbox(jobStatus, job.firstShiftStartAt, job);
    return !isDisabled;
  });

  const COMMON_COLUMNS = [
    'Skill',
    'Timeframe',
    {
      name: 'Shift Time',
      style: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '5px',
        marginTop: '5px',
      },
      badge: (
        <TZBadge
          timezone={order.jobs[0].address.timezone}
          date={order.jobs[0].firstShiftStartAt}
          locale={true}
        />
      ),
    },
    'GravyWorkers',
    'Status',
  ];

  const handleSelectAll = (e: any) => {
    if (e.target.checked) {
      const selectedJobs = validSelectableJobs?.map((job) => {
        return {
          jurisdiction: job.address.state,
          label: `${format(new Date(job.firstShiftStartAt), 'PP')} - ${format(
            new Date(job.lastShiftEndAt),
            'PP',
          )}`,
          ...job,
          jobWorkers: getHiredWorkersByJobWorkers(job.jobWorkers as any),
          jobOffers: job.jobOffers.filter((item) => item.acceptedAt == null),
        };
      });
      setSelecteds(selectedJobs);
    } else {
      setSelecteds([]);
    }
  };

  const containsFutureJobs = !!order.jobs.find((job) => {
    const jobStatus = getJobStatusByJob(job);
    if (!isJobCompOrCancel(jobStatus)) return true;
    return false;
  });

  const isGIG = () => {
    if (order && order.jobs && order.jobs.length > 0) {
      return order.jobs[0].jobType === JobTypeEnum.GIG;
    }

    return true;
  };

  const showHireOption = () => {
    return (
      !currentAdminIsCustomerAdmin &&
      !order.cancelledAt &&
      ((!isGIG() && order.account.customer.ltaAllowed) || isGIG())
    );
  };

  const ACTIONS =
    isGIG() && containsFutureJobs
      ? [
          {
            label: 'Invite Employee',
            a11yLabel: 'Invite',
            onAction: () =>
              showActionModal({
                context: 'invite',
                customer,
              }),
          },
        ]
      : [];

  return (
    <Page
      headerPadding={phoneOnly}
      noPadding={phoneOnly}
      primaryAction={actionButton}
      size={phoneOnly ? 'full' : 'xl'}
      subtitle={subtitle}
      title={
        <Stack gap={phoneOnly ? 4 : 8}>
          <Title>{`Order #${order.id}`}</Title>
          {taxTypeLabel && !currentAdminIsCustomerAdmin && (
            <Badge label={taxTypeLabel} status="neutral" />
          )}
          {!isGIG() && <Badge label="LTA" status={'themeDefault'} />}
        </Stack>
      }
    >
      <PrimarySecondaryLayout
        css={{ gap: phoneOnly ? 0 : 24 }}
        flipped={phoneOnly}
      >
        <PrimarySecondaryLayout.Primary>
          <Card
            style={{ overflow: 'auto' }}
            actions={undefined}
            actionsDropdown={
              showHireOption() ? (
                <PermissionComponent id="staff-job-btn">
                  <Button
                    a11yLabel={'Staff Job'}
                    disabled={!selecteds || selecteds.length === 0}
                    id="staff-job-btn"
                    label="Staff Job"
                    type="button"
                    onClick={() => setShowStaffModel(true)}
                  />
                </PermissionComponent>
              ) : undefined
            }
            noRadius={phoneOnly}
            title="Jobs"
          >
            {order.jobs.length > 0 ? (
              <Table>
                {!phoneOnly && (
                  <TableHeader
                    checkBoxHeader={
                      currentAdminIsCustomerAdmin ? (
                        <></>
                      ) : (
                        <TableHeading>
                          <div style={{ top: '5px' }}>
                            <Option
                              appearance="checkbox"
                              checked={
                                selecteds?.length === validSelectableJobs.length
                              }
                              disabled={validSelectableJobs.length === 0}
                              id={`order-selectall`}
                              label=""
                              type="checkbox"
                              onChange={handleSelectAll}
                            />
                          </div>
                        </TableHeading>
                      )
                    }
                    fields={COMMON_COLUMNS}
                  />
                )}
                <tbody>
                  {order.jobs.map((job: JobItem) => {
                    const isSelected = selecteds?.some(
                      (selected) => selected.id === job.id,
                    );
                    return (
                      <OrderJobTableItem
                        key={job.id}
                        columnHeaders={COMMON_COLUMNS}
                        currentAdminIsCustomerAdmin={
                          currentAdminIsCustomerAdmin
                        }
                        disableSelect={currentAdminIsCustomerAdmin}
                        isSelected={isSelected}
                        job={job}
                        onSelect={handleSelect}
                      />
                    );
                  })}
                </tbody>
              </Table>
            ) : (
              <QueryEmptyState
                query={query}
                text="If there were any, they would be here."
                title="No jobs available"
              />
            )}
          </Card>
          {showStaffingModel && selecteds && (
            <WorkerStaffingModel
              customerId={order.account.customer.id}
              hideModal={(refetch) => {
                if (refetch) {
                  query.refetch();
                  setSelecteds([]);
                }
                setShowStaffModel(false);
              }}
              jobTaxType={order.account.customer.jobTaxType!}
              selectedJobs={selecteds}
              timezone={order.jobs[0].address.timezone}
            />
          )}
        </PrimarySecondaryLayout.Primary>
        <PrimarySecondaryLayout.Secondary>
          <Stack vertical gap={!phoneOnly ? 24 : 0}>
            <ClientCard order={order} />
            <SummaryCard order={order} />
          </Stack>
        </PrimarySecondaryLayout.Secondary>
      </PrimarySecondaryLayout>
    </Page>
  );
};

export default Order;
