import { CloseOutlined } from '@mui/icons-material';
import {
  Dialog,
  DialogContent,
  IconButton,
  InputAdornment,
  ListSubheader,
  MenuItem,
  MenuList,
  TextField,
  Checkbox,
  ListItemText,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import styled from '@/styles';
import { useMemo, useState } from 'react';
import { theme } from '@/styles/colors';
import Button from '@/elements/Button';
import useMediaQuery from '@/hooks/useMediaQuery';

export type OptionItem = {
  id: string;
  name: string;
};

interface Props {
  title: string;
  items: OptionItem[];
  initialSelection?: string[];
  hideModal: () => void;
  showFilter?: boolean;
  updateFilters?: (selectedItems: string[]) => void;
  selectAll?: boolean;
}

const styles = {
  checkbox: {
    borderRadius: '20px',
    '&.Mui-checked': {
      color: theme.themeDefault,
    },
  },
  menuItem: {
    fontSize: '.875rem',
    fontWeight: 600,
  },
};

const Menu = styled(MenuList, {
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  li: {
    display: 'flex',
    gap: '16px',
    alignItems: 'center',
    color: '#1C1C1C',
    fontSize: '$lg',
    padding: '0',
    minHeight: '24px',
    svg: {
      height: '24px',
      width: '24px',
      margin: '0',
    },
  },
});

const HeaderDialog = styled('span', {
  padding: '10px 20px 15px 25px',
  background: 'linear-gradient(180deg, #EEFFEC 38.16%, #FFF 107.94%)',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const TitleDialog = styled('span', {
  color: '#45A735',
  fontSize: '16px',
  fontWeight: '600',
});

const OptionsModal = ({
  title,
  items,
  hideModal,
  showFilter,
  updateFilters,
  initialSelection,
  selectAll,
}: Props) => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedItems, setSelectedItems] = useState<string[]>(
    initialSelection ? initialSelection : [],
  );

  const phoneKeyboardOpen = useMediaQuery('(max-height: 750px)');
  const phoneOnly = useMediaQuery('(max-width: 559px)');

  const filteredItems = useMemo(() => {
    return items.filter((skill) =>
      skill.name.toLowerCase().includes(searchTerm.toLowerCase()),
    );
  }, [items, searchTerm]);

  const onOptionClick = (id: string) => {
    setSelectedItems((prev) => {
      if (prev.includes(id)) {
        return prev.filter((item) => item !== id);
      }
      return [...prev, id];
    });
  };

  const onSelectAll = () => {
    if (selectedItems.length === filteredItems.length) {
      setSelectedItems([]);
    } else {
      setSelectedItems(filteredItems.map((item) => item.id));
    }
  };

  return (
    <Dialog
      PaperProps={{
        style: {
          height:
            filteredItems.length > 5
              ? phoneOnly && phoneKeyboardOpen
                ? '90vh'
                : '50vh'
              : 'fit-content',
          borderRadius: '15px',
        },
      }}
      aria-labelledby="options-dialog"
      fullScreen={true}
      open={true}
      sx={{
        '& .MuiDialog-container': { alignItems: 'end', marginBottom: '2px' },
      }}
      onClose={hideModal}
    >
      <HeaderDialog>
        <TitleDialog> {title}</TitleDialog>
        <IconButton aria-label="close" onClick={hideModal}>
          <CloseOutlined />
        </IconButton>
      </HeaderDialog>
      <DialogContent
        dividers
        style={{ padding: '0px 25px 25px', border: 'none' }}
      >
        <ListSubheader style={{ paddingTop: '10px' }}>
          {showFilter && (
            <TextField
              size="small"
              autoFocus
              placeholder="Type to search..."
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyDown={(e) => {
                if (e.key !== 'Escape') {
                  e.stopPropagation();
                }
              }}
            />
          )}
        </ListSubheader>

        <Menu
          sx={{ paddingTop: '10px', paddingBottom: '0px', overflowY: 'auto' }}
        >
          {selectAll && (
            <MenuItem onClick={() => onSelectAll()}>
              <Checkbox
                checked={selectedItems.length === filteredItems.length}
                sx={styles.checkbox}
              />
              <ListItemText
                primary="Select All"
                primaryTypographyProps={styles.menuItem}
              />
            </MenuItem>
          )}
          {filteredItems.map((opt) => (
            <MenuItem
              key={opt.id}
              value={opt.id}
              onClick={() => onOptionClick(opt.id)}
            >
              <Checkbox
                checked={selectedItems.includes(opt.id)}
                sx={styles.checkbox}
              />
              <ListItemText
                primary={opt.name}
                primaryTypographyProps={styles.menuItem}
              />
            </MenuItem>
          ))}
        </Menu>
      </DialogContent>
      <Button
        style={{
          width: '80%',
          marginTop: '10px',
          marginBottom: '20px',
          alignSelf: 'center',
        }}
        onClick={() => {
          updateFilters && updateFilters(selectedItems);
          hideModal();
        }}
      >
        Apply
      </Button>
    </Dialog>
  );
};

export default OptionsModal;
