import Stack from '@/components/Stack';
import useMediaQuery from '@/hooks/useMediaQuery';
import { nTimes } from '@/util/array';
import { addDays, differenceInCalendarDays, format } from 'date-fns';
import { getShiftsByDate } from './index';
import { useEffect, useMemo } from 'react';
import useAnalytics from '@/util/analytics';
import { GAEvent } from '@/constants/gaevents';
import useAuth from '@/hooks/useAuth';
import { MONDAY_OF_WEEK } from '@/util/constants';

const ShiftDate = ({
  startDate,
  shifts,
  selectedDate,
  setSelectedDate,
  setSelectedShifts,
  loading,
  loadData,
  setLoadData,
}) => {
  const { logEvent } = useAnalytics();
  const { currentAdmin } = useAuth();
  const shiftsByDate = useMemo(() => getShiftsByDate(shifts), [shifts]);
  const phoneOnly = useMediaQuery('(max-width: 559px)');

  useEffect(() => {
    if (selectedDate === null) {
      const queryParams = new URLSearchParams(window.location.search);
      let date = new Date();
      if (queryParams.has('cd')) {
        const queryDate = queryParams.get('cd');
        date = new Date(`${queryDate}T00:00:00`);
        const dateDiff = differenceInCalendarDays(date, startDate);
        if (dateDiff < 0 || dateDiff > 6) {
          date = startDate;
        }
      } else if (queryParams.has('sd')) {
        const queryDate = queryParams.get('sd');
        date = new Date(`${queryDate}T00:00:00`);

        if (date >= MONDAY_OF_WEEK && date <= addDays(MONDAY_OF_WEEK, 6)) {
          date = new Date();
        }
      }

      const dateIndex = differenceInCalendarDays(date, startDate);
      selectData(
        date,
        shiftsByDate[addDays(startDate, dateIndex).getDate()] ?? [],
      );
    } else if (loadData) {
      const queryParams = new URLSearchParams(window.location.search);
      let dateLoaded = new Date(selectedDate);
      if (queryParams.has('cd')) {
        const queryDate = queryParams.get('cd');
        dateLoaded = new Date(`${queryDate}T00:00:00`);
      }

      const dateIndex = differenceInCalendarDays(dateLoaded, startDate);
      if (dateLoaded >= startDate && dateLoaded <= addDays(startDate, 6)) {
        setSelectedShifts(
          shiftsByDate[addDays(startDate, dateIndex).getDate()] ?? [],
        );
      }
      setLoadData(false);
    }
  }, [loading, loadData]);

  const selectData = (date, shifts) => {
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set('cd', format(date, 'yyyy-MM-dd'));
    window.history.replaceState(
      {},
      '',
      `${window.location.pathname}?${queryParams.toString()}`,
    );

    setSelectedDate(date.toDateString());
    setSelectedShifts(shifts);
    logEvent(GAEvent.DashboardSkillsFilter, currentAdmin?.user.id);
  };

  if (!phoneOnly) {
    return <></>;
  }

  return (
    <Stack className="shift-date-container">
      {nTimes(7, (index) => {
        const date = addDays(startDate, index);
        const shifts = shiftsByDate[date.getDate()] ?? [];

        return (
          <Stack
            className={
              'shift-date ' +
              getShiftDateClassName(shifts, selectedDate, date.toDateString())
            }
            onClick={() => selectData(date, shifts)}
          >
            <Stack gap={0} className="shift-date-content">
              <div className="header"></div>
              <div className="month">
                {date.toLocaleString('default', { weekday: 'short' })}
              </div>
              <div className="date">{date.getDate()}</div>
              <div className="needs-approval">
                {shifts?.filter((shift) => shift.job.needsApproval)?.length >
                  0 && <div className="shift-date-needs-approval"></div>}
              </div>
            </Stack>
          </Stack>
        );
      })}
    </Stack>
  );
};

const getShiftDateClassName = (
  shiftsForDate,
  selectedDateIndex,
  currentDateIndex,
) => {
  if (shiftsForDate.length === 0) {
    if (selectedDateIndex === currentDateIndex)
      return 'shift-empty-active-date';
    return 'shift-empty-date';
  } else if (selectedDateIndex === currentDateIndex) {
    return 'shift-active-date';
  }
  return '';
};

export default ShiftDate;
