export const getDisplayText = (selectedNames: string[]): string => {
  let displayText = selectedNames[0] ?? '';
  let additionalCount = selectedNames.length - 1;

  if (selectedNames.length === 1) {
    return displayText.length <= 14
      ? displayText.padEnd(14)
      : `${displayText.substring(0, 11)}...`;
  }

  const additionalCountText = `+${additionalCount}`;
  const maxTextLength = 14 - additionalCountText.length - 3;

  displayText =
    displayText.length > maxTextLength
      ? `${displayText.substring(0, maxTextLength)}...`
      : `${displayText}...`;

  return (displayText + additionalCountText).padEnd(14);
};
