import { useReactiveVar } from '@apollo/client';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import * as Sentry from '@sentry/react';

import { currentAdminVar } from './apollo/cache';
import { WorkerTabs } from './axios/types';
import { isIOS, isWeb } from './platform';

import { GAEvent, eventMap } from '@/constants/gaevents';
import { IS_QA } from '@/constants/env';

const useAnalytics = (trackingId?: string) => {
  let userID;
  useEffect(() => {
    if (trackingId) {
      ReactGA.initialize(trackingId, {
        gaOptions: {
          debug_mode: IS_QA,
        },
        gtagOptions: {
          debug_mode: IS_QA,
        },
      });
    }
  }, []);

  const resetGoogleAnalytics = () => {
    ReactGA.set({
      user_id: null,
    });
  };
  const currentAdmin = useReactiveVar(currentAdminVar);

  const setUser = (userId: string) => {
    if (!process.env.GA_TRACKING_ID) return;
    userID = userId;
    ReactGA.set({
      user_id: userId,
    });
    userID = userId;
  };

  const logEvent = (
    event: GAEvent | WorkerTabs,
    value?: number | string,
    paramters?: any,
  ) => {
    if (!process.env.GA_TRACKING_ID) return;

    value = value ? Number(value) : undefined;
    const { category, action, label } = eventMap[event];

    const defaultUserData = {
      role: currentAdmin?.role === 0 ? 'customer_admin' : 'tenant_admin',
      user_id: currentAdmin?.user.id,
      admin_name: `${currentAdmin?.user.firstName} ${currentAdmin?.user.lastName}`,
      platform: isWeb() ? 'web' : isIOS() ? 'ios' : 'android',
      ...(currentAdmin?.role === 0 && {
        client_name: currentAdmin?.customer.name,
      }),
    };

    if (isIOS()) {
      createRequest(event, { ...paramters, ...defaultUserData });
      return;
    }
    ReactGA.send({
      hitType: 'event',
      eventCategory: category,
      eventAction: action,
      eventLabel: label,
      ...paramters,
      ...(defaultUserData.role !== '' && defaultUserData),
    });
  };

  const logPageView = (path: string) => {
    ReactGA.send({ hitType: 'pageview', path });
  };

  const createRequest = (event: GAEvent | WorkerTabs, paramters?: any) => {
    const myHeaders = new Headers();
    myHeaders.append('content-type', 'application/json');
    myHeaders.append('user-agent', navigator.userAgent);

    const raw = JSON.stringify({
      client_id: process.env.BUNDLE_ID,
      user_id: userID,
      non_personalized_ads: true,
      events: [
        {
          name: eventMap[event].label,
          params: {
            ...paramters,
            engagement_time_msec: 100,
            session_id: `ios_session_${userID}`,
          },
        },
      ],
    });

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
    };

    const url = `https://www.google-analytics.com/mp/collect?&measurement_id=${process.env.GA_TRACKING_ID}&api_secret=${process.env.GA_IOS_APP_SECRET}`;
    fetch(url, requestOptions)
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => {
        Sentry.captureException(error);
      });
  };

  return {
    logEvent,
    logPageView,
    setUser,
    resetGoogleAnalytics,
  };
};

export default useAnalytics;
