import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '..';

import {
  Address,
  Maybe,
  Skill,
  FillStatusEnum,
  VisibilityStatusEnum,
  JobStatusEnum,
  Scalars,
  InputMaybe,
  IdMembershipFilterInput,
  JobDashboardStatusEnum,
} from '@/types/graphql';

export enum TaxTypeEnumVoid {
  BOTH = 'BOTH',
  TAX_1099 = 'TAX_1099',
  TAX_W2 = 'TAX_W2',
}
interface FiltersState {
  workerCompleteTableFilters: {
    address: Maybe<Address>;
    skillArray: Array<Skill>;
    taxType: TaxTypeEnumVoid;
    certArray: any[];
  };

  dashboardFilters?: {
    fillStatus?: InputMaybe<FillStatusEnum> | undefined;
    dashboardVisibility?: InputMaybe<Array<VisibilityStatusEnum> | undefined>;
    customerIds?: InputMaybe<Array<Scalars['ID']['input']> | undefined>;
    accounts?: InputMaybe<IdMembershipFilterInput | undefined>;
    skillIds?: InputMaybe<Array<Scalars['ID']['input']> | undefined>;
    regionIds?: InputMaybe<Array<Scalars['ID']['input']> | undefined>;
    dashboardJobStatus?: InputMaybe<Array<JobStatusEnum> | undefined>;
    jobDashboardStatus?: InputMaybe<Array<JobDashboardStatusEnum> | undefined>;
  };
}

const initialState: FiltersState = {
  workerCompleteTableFilters: {
    address: null,
    skillArray: [],
    taxType: TaxTypeEnumVoid.BOTH,
    certArray: [],
  },
  dashboardFilters: {},
};

export const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setWorkerCompleteTableFilters: (state, action: PayloadAction<any>) => {
      state.workerCompleteTableFilters = action.payload;
    },
    resetWorkerTableFilters: (state) => {
      state.workerCompleteTableFilters =
        initialState.workerCompleteTableFilters;
    },
    setDashboardFilters: (state, action: PayloadAction<any>) => {
      state.dashboardFilters = action.payload;
    },
    setDashboardFillStatus: (
      state,
      action: PayloadAction<FillStatusEnum | undefined>,
    ) => {
      state.dashboardFilters!.fillStatus = action.payload;
    },
    setDashboardVisibility: (
      state,
      action: PayloadAction<Array<VisibilityStatusEnum> | undefined>,
    ) => {
      state.dashboardFilters!.dashboardVisibility = action.payload;
    },
    setDashboardCustomerIds: (
      state,
      action: PayloadAction<Array<Scalars['ID']['input']> | undefined>,
    ) => {
      state.dashboardFilters!.customerIds = action.payload;
    },
    setDashboardAccounts: (
      state,
      action: PayloadAction<IdMembershipFilterInput | undefined>,
    ) => {
      state.dashboardFilters!.accounts = action.payload;
    },
    setDashboardSkillIds: (
      state,
      action: PayloadAction<Array<Scalars['ID']['input']> | undefined>,
    ) => {
      state.dashboardFilters!.skillIds = action.payload;
    },
    setDashboardRegionIds: (
      state,
      action: PayloadAction<Array<Scalars['ID']['input']> | undefined>,
    ) => {
      state.dashboardFilters!.regionIds = action.payload;
    },
    setDashboardJobStatus: (
      state,
      action: PayloadAction<Array<JobStatusEnum> | undefined>,
    ) => {
      state.dashboardFilters!.dashboardJobStatus = action.payload;
    },
    setJobDashboardStatus: (
      state,
      action: PayloadAction<Array<JobDashboardStatusEnum> | undefined>,
    ) => {
      state.dashboardFilters!.jobDashboardStatus = action.payload;
    },
    resetDashboardFilters: (state) => {
      state.dashboardFilters = {};
    },
  },
});

export const selectWorkerCompleteFilters = (state: RootState) =>
  state.filters.workerCompleteTableFilters;

export const selectDashboardFilters = (state: RootState) =>
  state.filters.dashboardFilters;

export const {
  setWorkerCompleteTableFilters,
  resetWorkerTableFilters,
  setDashboardFilters,
  resetDashboardFilters,
  setDashboardFillStatus,
  setDashboardVisibility,
  setDashboardCustomerIds,
  setDashboardAccounts,
  setDashboardSkillIds,
  setDashboardRegionIds,
  setDashboardJobStatus,
  setJobDashboardStatus,
} = filtersSlice.actions;

export default filtersSlice.reducer;
