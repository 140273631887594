import React, { useState } from 'react';
import SkillsSelect from './SkillsSelect';
import JobStatusSelect from './JobStatusSelect';
import JobFilledSelect from './JobFilledSelect';
import AdvancedFilters from './AdvancedFilters';
import Button from '@/elements/Button';
import { TurnedIn, TurnedInNot } from '@mui/icons-material';
import theme from '@/styles/colors';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { resetDashboardFilters } from '@/store/slices/filtersSlice';
import ClientsSelect from './ClientsSelect';
import useAuth from '@/hooks/useAuth';
import { useUserFilterSaveMutation } from '@/graphql';
import { UserFilterTypeEnum } from '@/types/graphql';
import AccountsSelect, { Item } from './AccountsSelect';
import useAnalytics from '@/util/analytics';
import { GAEvent } from '@/constants/gaevents';
import OptionsModal, { OptionItem } from './OptionsModal';
import { useModal } from 'react-modal-hook';
import useMediaQuery from '@/hooks/useMediaQuery';
import AccountOptionsModal from './AccountOptionsModal';

const DashboardFiltersRevamp = ({
  bookMarked,
  setBookMarked,
  refetch,
}: {
  bookMarked: boolean;
  setBookMarked: (arg: boolean) => void;
  refetch: () => void;
}) => {
  const dispatch = useAppDispatch();
  const handleClearAll = () => {
    setBookMarked(false);
    dispatch(resetDashboardFilters());
  };
  const phoneOnly = useMediaQuery('(max-width: 559px)');
  const { logEvent } = useAnalytics();

  const { currentAdminIsCustomerAdmin, currentAdmin } = useAuth();

  const [saveUserFilter] = useUserFilterSaveMutation();
  const filters = useAppSelector((state) => state.filters.dashboardFilters);

  const saveUserFilters = async () => {
    await saveUserFilter({
      variables: {
        filterType: UserFilterTypeEnum.DASHBOARD,
        filterValue: encodeURIComponent(JSON.stringify(filters)),
      },
    });

    refetch();
    setBookMarked(true);
    logEvent(GAEvent.BookMarkDashboardFilters, currentAdmin?.user.id);
  };

  const [modalOptions, setModalOptions] = useState<{
    title: string;
    items: OptionItem[];
    selectedItems: string[];
    showFilter: boolean;
    updateFilters: (selectedItem: string[]) => void;
    selectAll?: boolean;
  }>({
    title: '',
    items: [],
    selectedItems: [],
    showFilter: false,
    updateFilters: (selectedItem: string[]) => {},
    selectAll: false,
  });

  const [modalOptionsAccounts, setModalOptionsAccounts] = useState<{
    title: string;
    items: Item[];
    selectedItems: string[];
    updateFilters: (selectedItem: string[]) => void;
    selectAll?: boolean;
    setShowMobileModal: (arg: boolean) => void;
  }>({
    title: '',
    items: [],
    selectedItems: [],
    updateFilters: (selectedItem: string[]) => {},
    selectAll: false,
    setShowMobileModal: (arg: boolean) => {},
  });

  const [showOption, hideOptionModal] = useModal(
    () => (
      <OptionsModal
        hideModal={hideOptionModal}
        items={modalOptions.items}
        title={modalOptions.title}
        showFilter={modalOptions.showFilter}
        initialSelection={modalOptions.selectedItems}
        updateFilters={modalOptions.updateFilters}
        selectAll={modalOptions.selectAll}
      />
    ),
    [modalOptions],
  );

  const [showOptionAccounts, hideOptionModalAccounts] = useModal(
    () => (
      <AccountOptionsModal
        hideModal={hideOptionModalAccounts}
        items={modalOptionsAccounts.items}
        title={modalOptionsAccounts.title}
        initialSelection={modalOptionsAccounts.selectedItems}
        updateFilters={modalOptionsAccounts.updateFilters}
        selectAll={modalOptionsAccounts.selectAll}
        setShowMobileModal={modalOptionsAccounts.setShowMobileModal}
      />
    ),
    [modalOptionsAccounts],
  );

  const showMobileModal = (
    title: string,
    items: OptionItem[],
    selectedItems: string[],
    showFilter,
    updateFilters: (selectedItem: string[]) => void,
    selectAll?: boolean,
  ) => {
    setModalOptions({
      title,
      items,
      selectedItems,
      showFilter,
      updateFilters,
      selectAll,
    });
    showOption();
  };

  const showMobileModalAccounts = (
    title: string,
    items: Item[],
    selectedItems: string[],
    updateFilters: (selectedItem: string[]) => void,
    setShowMobileModal: (arg: boolean) => void,
    selectAll?: boolean,
  ) => {
    setModalOptionsAccounts({
      title,
      items,
      selectedItems,
      updateFilters,
      selectAll,
      setShowMobileModal,
    });
    showOptionAccounts();
  };

  return (
    <div
      className={
        'dashboard-filters-section ' +
        (phoneOnly && 'dashboard-filters-section-for-mobile')
      }
    >
      <div style={{ display: 'inline-flex', marginRight: '8px' }}>
        {bookMarked ? (
          <TurnedIn
            style={{ color: theme.themeDefault, cursor: 'pointer', top: '8px' }}
          />
        ) : (
          <TurnedInNot
            style={{ color: '#000000', cursor: 'pointer', top: '8px' }}
            onClick={saveUserFilters}
          />
        )}
      </div>

      {!currentAdminIsCustomerAdmin && (
        <ClientsSelect
          setBookMarked={setBookMarked}
          showMobileModal={showMobileModal}
          phoneOnly={phoneOnly}
        />
      )}
      <AccountsSelect
        setBookMarked={setBookMarked}
        showMobileModalAccounts={showMobileModalAccounts}
        phoneOnly={phoneOnly}
      />
      <SkillsSelect
        setBookMarked={setBookMarked}
        showMobileModal={showMobileModal}
        phoneOnly={phoneOnly}
      />
      <JobStatusSelect
        setBookMarked={setBookMarked}
        showMobileModal={showMobileModal}
        phoneOnly={phoneOnly}
      />
      <JobFilledSelect
        setBookMarked={setBookMarked}
        showMobileModal={showMobileModal}
        phoneOnly={phoneOnly}
      />
      {!currentAdminIsCustomerAdmin && (
        <AdvancedFilters setBookMarked={setBookMarked} />
      )}
      <Button
        appearance={'plain'}
        id="clear-all-dashboard-filters"
        style={{ marginLeft: '10px', fontWeight: '500' } as any}
        onClick={() => handleClearAll()}
      >
        Clear all
      </Button>
    </div>
  );
};

export default DashboardFiltersRevamp;
