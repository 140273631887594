import useModal from '@area2k/use-modal';

import Card from '@/components/Card';
import TextStack from '@/components/TextStack';
import { Body } from '@/components/Typography';
import { GetWorkerQuery, SsnVerificationStatusEnum } from '@/types/graphql';
import ResetEmploymentEligibilityModal from './ResetEmploymentEligibilityModal';

import './index.css';

type Props = {
  worker: GetWorkerQuery['worker'];
};

const EmploymentEligibility = ({ worker }: Props) => {
  const [showChangeModal, hideChangeModal] = useModal(
    () => (
      <ResetEmploymentEligibilityModal
        hideModal={hideChangeModal}
        worker={worker}
      />
    ),
    [worker],
  );

  return (
    <Card.Section
      actions={
        true || worker.verificationStatus === SsnVerificationStatusEnum.FAILED
          ? [
              {
                a11yLabel: 'Reset',
                label: 'Reset',
                onAction: showChangeModal,
                id: 'btn-reset',
              },
            ]
          : undefined
      }
      title="Employment Eligibility"
    >
      <TextStack>
        <Body className="employment-eligibility-container">
          {worker.verificationStatus === SsnVerificationStatusEnum.PENDING && (
            <span className="pending">Pending</span>
          )}
          {worker.verificationStatus === SsnVerificationStatusEnum.VERIFIED && (
            <span className="passed">Passed</span>
          )}
          {worker.verificationStatus === SsnVerificationStatusEnum.FAILED && (
            <span className="failed">Failed</span>
          )}
        </Body>
      </TextStack>
    </Card.Section>
  );
};

export default EmploymentEligibility;
