import React, { useEffect, useState, useCallback } from 'react';
import {
  Select,
  MenuItem,
  Box,
  Checkbox,
  ListItemText,
  InputLabel,
  FormControl,
} from '@mui/material';
import { FillStatusEnum } from '@/types/graphql';
import theme from '@/styles/colors';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { setDashboardFillStatus } from '@/store/slices/filtersSlice';
import { SelectChangeEvent } from '@mui/material';
import Typography from '@mui/material/Typography';
import useAnalytics from '@/util/analytics';
import { GAEvent } from '@/constants/gaevents';
import useAuth from '@/hooks/useAuth';
import { OptionItem } from './OptionsModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';

const filledFilter = [
  { label: 'Filled', value: FillStatusEnum.FILLED },
  { label: 'Unfilled', value: FillStatusEnum.UNFILLED },
];

const styles = {
  inputLabel: {
    color: theme.themeDefault,
    fontSize: '.875rem',
    fontWeight: 600,
    '&.Mui-focused': {
      color: theme.themeDefault,
      fontWeight: 500,
    },
    transform: 'translate(14px, 10px) scale(1)',
    '&.MuiInputLabel-shrink': {
      color: '#262626',
      fontWeight: 500,
      transform: 'translate(14px, -6px) scale(0.75)',
    },
    transition: 'transform 0.2s ease-in-out, color 0.2s ease-in-out',
  },
  checkbox: {
    '&.Mui-checked': {
      color: theme.themeDefault,
    },
  },
  menuPaper: {
    maxHeight: 224,
  },
  menuItem: {
    fontSize: '.875rem',
    fontWeight: 600,
  },
};

const JobFilledSelect = ({
  setBookMarked,
  showMobileModal,
  phoneOnly,
}: {
  setBookMarked: (arg: boolean) => void;
  showMobileModal: (
    title: string,
    items: OptionItem[],
    selectedItems: string[],
    showFilter: boolean,
    updateFilters: (selectedItem: string[]) => void,
    selectAll?: boolean,
  ) => void;
  phoneOnly: boolean;
}) => {
  const { logEvent } = useAnalytics();
  const { currentAdmin } = useAuth();
  const dispatch = useAppDispatch();
  const statusInStore = useAppSelector(
    (state) => state.filters.dashboardFilters?.fillStatus,
  );
  const [selectedItems, setSelectedItems] = useState<FillStatusEnum[]>([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setSelectedItems(
      statusInStore === FillStatusEnum.ALL
        ? filledFilter.map((filter) => filter.value)
        : statusInStore
          ? [statusInStore]
          : [],
    );
  }, [statusInStore]);

  const handleChange = useCallback(
    (event: SelectChangeEvent<FillStatusEnum[]>) => {
      const value = event.target.value as FillStatusEnum[];
      if (value.includes(FillStatusEnum.ALL)) {
        if (selectedItems.length === filledFilter.length) {
          setSelectedItems([]);
          dispatch(setDashboardFillStatus(undefined));
        } else {
          setSelectedItems(filledFilter.map((filter) => filter.value));
          dispatch(setDashboardFillStatus(FillStatusEnum.ALL));
        }
      } else {
        setSelectedItems(value);
        dispatch(
          setDashboardFillStatus(
            value.length > 1 ? FillStatusEnum.ALL : value[0],
          ),
        );
      }

      setBookMarked(false);
      logEvent(GAEvent.DashboardFillStatusFilter, currentAdmin?.user.id);
    },
    [selectedItems],
  );

  const renderValue = useCallback((selected: string[]) => {
    if (selected.length === 0) {
      return <span>Fill Status</span>;
    }
    const firstFilter = filledFilter.find(
      (item) => item.value === selected[0],
    )?.label;
    const additionalCount = selected.length - 1;
    return (
      <Box display="flex" alignItems="center">
        <span>{firstFilter}</span>
        {additionalCount > 0 && <span>+{additionalCount}</span>}
      </Box>
    );
  }, []);

  return (
    <FormControl style={{ maxWidth: '150px', marginRight: '10px' }}>
      <InputLabel id="filled-status-multi-select-label" sx={styles.inputLabel}>
        Fill Status
      </InputLabel>
      <Select
        labelId="filled-status-multi-select-label"
        label="Fill Status"
        multiple
        value={selectedItems}
        onChange={handleChange}
        renderValue={(selected) => renderValue(selected as string[])}
        fullWidth
        style={{ borderRadius: '8px' }}
        sx={{
          minWidth: 115,
          height: 40,
          color: theme.themeDefault,
          fontSize: '.875rem',
          fontWeight: 600,
          '.MuiOutlinedInput-notchedOutline': {
            borderColor:
              selectedItems.length > 0 ? '#262626' : theme.themeDefault,
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor:
              selectedItems.length > 0 ? '#262626' : theme.themeDefault,
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor:
              selectedItems.length > 0 ? '#262626' : theme.themeDefault,
            borderWidth: '1px',
          },
        }}
        open={open}
        IconComponent={(props) => (
          <FontAwesomeIcon
            style={{ color: theme.themeDefault }}
            icon={open ? faAngleUp : faAngleDown}
            {...props}
          />
        )}
        onOpen={() => {
          phoneOnly
            ? showMobileModal(
                'Fill Status',
                [
                  { name: 'Filled', id: FillStatusEnum.FILLED },
                  { name: 'Unfilled', id: FillStatusEnum.UNFILLED },
                ],
                selectedItems,
                false,
                (selected: string[]) => {
                  handleChange({
                    target: { value: selected } as SelectChangeEvent<string[]>,
                  });
                },
                true,
              )
            : setOpen(true);
        }}
        onClose={() => setOpen(false)}
        MenuProps={{
          autoFocus: false,
          PaperProps: {
            style: styles.menuPaper,
          },
        }}
      >
        <MenuItem
          disableRipple
          style={{ cursor: 'default', pointerEvents: 'none' }}
        >
          <Typography
            variant="h6"
            style={{
              fontSize: '.875rem',
              fontWeight: 'bold',
              color: '#000',
              opacity: 100,
            }}
          >
            Fill Status
          </Typography>
        </MenuItem>
        <MenuItem value={FillStatusEnum.ALL}>
          <Checkbox
            checked={selectedItems.length === filledFilter.length}
            sx={styles.checkbox}
          />
          <ListItemText
            primary="Select All"
            primaryTypographyProps={styles.menuItem}
          />
        </MenuItem>
        {filledFilter.map((filter) => (
          <MenuItem key={filter.label} value={filter.value}>
            <Checkbox
              checked={selectedItems.includes(filter.value)}
              sx={styles.checkbox}
            />
            <ListItemText
              primary={filter.label}
              primaryTypographyProps={styles.menuItem}
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default JobFilledSelect;
