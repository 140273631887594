import { VariantProps, CSS } from '@stitches/react';

import styled from '@/styles';
import colors from '@/styles/colors';

const Wrapper = styled('span', {
  $$bgColor: '$colors$neutralA12',
  $$color: '$colors$textDefault',

  padding: '4px 8px',

  backgroundColor: '$$bgColor',
  borderRadius: '$round',
  color: '$$color',

  fontSize: '$sm',

  variants: {
    size: {
      sm: {},
      md: {
        fontSize: '$md',
      },
      lg: {
        fontSize: '$lg',
      },
    },
    status: {
      neutral: {
        $$bgColor: colors.badgeBrown,
        $$color: '$colors$whiteA96',
      },
      theme: {
        $$bgColor: '$colors$successTick',
        $$color: '$colors$whiteA96',
      },
      themeDefault: {
        $$color: 'white',
        $$bgColor: '$colors$themeDefault',
      },
      warning: {
        $$bgColor: colors.badgeGreen,
        $$color: '$colors$whiteA96',
      },
      danger: {
        $$bgColor: '$colors$dangerA12',
        $$color: '$colors$dangerDarker',
      },
      success: {
        $$bgColor: colors.badgelBlue,
        $$color: '$colors$whiteA96',
      },
      disabled: {
        $$bgColor: '$colors$neutralA12',
        $$color: '$colors$textLightest',
      },
      needsApproval: {
        $$bgColor: colors.needsApproval,
        $$color: '$colors$whiteA96',
      },
    },
  },

  defaultVariants: {
    size: 'sm',
    status: 'neutral',
  },
});

type Props = VariantProps<typeof Wrapper> & {
  label: string;
  css?: CSS;
};

const JobBadge = ({ label, ...props }: Props) => {
  return <Wrapper {...props}>{label}</Wrapper>;
};

export default JobBadge;
