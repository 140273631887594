import React, { useState, useMemo, useEffect } from 'react';
import {
  Select,
  MenuItem,
  Box,
  Checkbox,
  ListItemText,
  InputLabel,
  FormControl,
  CircularProgress,
  Typography,
  Tooltip,
  ListSubheader,
} from '@mui/material';
import { useGetCustomerAndAccountsQuery } from '@/graphql';
import useAuth from '@/hooks/useAuth';
import theme from '@/styles/colors';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { setDashboardAccounts } from '@/store/slices/filtersSlice';
import useAnalytics from '@/util/analytics';
import { GAEvent } from '@/constants/gaevents';
import { getDisplayText } from './helpers';
import { FixedSizeList } from 'react-window';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';

export type Item = {
  id: string;
  clientName: string;
  accounts: { id: string; name: string }[];
};

const styles = {
  inputLabel: {
    fontSize: '.875rem',
    fontWeight: 600,
    color: theme.themeDefault,
    '&.Mui-focused': {
      color: theme.themeDefault,
      fontWeight: 500,
    },
    '&.Mui-disabled': {
      color: 'gray', // Ensures label stays gray when disabled
    },
    transform: 'translate(14px, 10px) scale(1)',
    '&.MuiInputLabel-shrink': {
      color: '#262626',
      fontWeight: 500,
      transform: 'translate(14px, -6px) scale(0.75)',
    },
    transition: 'transform 0.2s ease-in-out, color 0.2s ease-in-out',
  },
  menuPaper: {
    maxHeight: '544px',
    maxWidth: '268px',
    marginTop: '0px',
  },
  checkbox: {
    borderRadius: '20px',
    '&.Mui-checked': {
      color: theme.themeDefault,
    },
  },
  menuItem: {
    fontSize: '.875rem',
    fontWeight: 600,
    padding: '8px 16px',
  },
};

const AccountsSelect = ({
  setBookMarked,
  showMobileModalAccounts,
  phoneOnly,
}: {
  setBookMarked: (arg: boolean) => void;
  showMobileModalAccounts: (
    title: string,
    items: Item[],
    selectedItems: string[],
    updateFilters: (selectedItem: string[]) => void,
    setShowMobileModal: (show: boolean) => void,
    selectAll?: boolean,
  ) => void;
  phoneOnly: boolean;
}) => {
  const { logEvent } = useAnalytics();
  const { currentAgency } = useAuth();
  const dispatch = useAppDispatch();
  const customerIds = useAppSelector(
    (state) => state.filters.dashboardFilters?.customerIds,
  );
  const { currentAdminIsCustomerAdmin, currentAdmin } = useAuth();
  const [open, setOpen] = useState(false);

  const { data, loading: isLoading } = useGetCustomerAndAccountsQuery({
    skip:
      !currentAdminIsCustomerAdmin &&
      (!customerIds || customerIds.length === 0),
    variables: {
      agencyId: currentAgency!.id,
      filters: {
        ids:
          currentAdminIsCustomerAdmin && currentAdmin
            ? [currentAdmin.customer.id]
            : customerIds || [],
      },
    },
  });

  const items = useMemo(() => {
    if (!data) return [];
    return data.agency.customers.items.map((customer) => ({
      id: customer.id,
      clientName: customer.name,
      accounts: customer.accounts.map((account) => ({
        id: account.id,
        name: account.name,
      })),
    }));
  }, [data]);

  const accountIdsInStore = useAppSelector(
    (state) => state.filters.dashboardFilters?.accounts?.value,
  );
  const [selectedItems, setSelectedItems] = useState<string[]>(
    accountIdsInStore || [],
  );

  const matchingItems = useMemo(() => {
    if (isLoading || !items || items.length === 0 || !accountIdsInStore)
      return [];
    return items
      .flatMap((item) => item.accounts)
      .filter((account) => accountIdsInStore.includes(account.id))
      .map((account) => account.id);
  }, [items, accountIdsInStore, isLoading]);

  useEffect(() => {
    const hasDifference =
      matchingItems.length !== selectedItems.length ||
      !matchingItems.every((id, index) => id === selectedItems[index]);

    if (!isLoading && hasDifference) {
      setSelectedItems(matchingItems);

      if (matchingItems.length > 0) {
        dispatch(setDashboardAccounts({ value: matchingItems }));
      } else {
        dispatch(setDashboardAccounts(undefined));
      }
    }
  }, [matchingItems, selectedItems, dispatch, items, isLoading]);

  const handleChange = (updatedSelectedItems: string[]) => {
    setSelectedItems(updatedSelectedItems);
    if (updatedSelectedItems.length > 0) {
      dispatch(setDashboardAccounts({ value: updatedSelectedItems }));
    } else {
      dispatch(setDashboardAccounts(undefined));
    }
    setBookMarked(false);
    logEvent(GAEvent.DashboardBillingAccountFilter, currentAdmin?.user.id);
    if (phoneOnly) {
      setShowMobileModal(false);
    }
  };

  const handleSelectAll = () => {
    if (selectedItems.length === items.flatMap((c) => c.accounts).length) {
      handleChange([]);
    } else {
      const allAccountIds = items.flatMap((customer) =>
        customer.accounts.map((account) => account.id),
      );
      handleChange(allAccountIds);
    }
  };

  const handleCheckboxChange = (accountId: string) => {
    const updatedSelectedItems = selectedItems.includes(accountId)
      ? selectedItems.filter((id) => id !== accountId)
      : [...selectedItems, accountId];
    handleChange(updatedSelectedItems);
  };

  const renderValue = (selected: string[]) => {
    if (selected.length === 0) {
      return <span>Billing Account</span>;
    }

    const selectedNames = selected
      .map((id) => {
        for (const customer of items) {
          const account = customer.accounts.find((acc) => acc.id === id);
          if (account) return account.name;
        }
        return null;
      })
      .filter(Boolean);

    if (selectedNames.length === 0) {
      return <span>Billing Account</span>;
    }
    return (
      <Box display="flex" alignItems="center">
        <span>{getDisplayText(selectedNames)}</span>
      </Box>
    );
  };

  const [showMobileModal, setShowMobileModal] = useState(false);

  useEffect(() => {
    if (phoneOnly && items.length > 0 && !isLoading && showMobileModal) {
      showMobileModalAccounts(
        'Billing Accounts',
        items,
        selectedItems,
        (updatedSelected: string[]) => {
          handleChange(updatedSelected);
        },
        setShowMobileModal,
        true,
      );
    }
  }, [items, selectedItems, phoneOnly, isLoading, showMobileModal]);

  const Row = ({
    index,
    style,
  }: {
    index: number;
    style: React.CSSProperties;
  }) => {
    if (index === 0) {
      return (
        <ListSubheader style={{ ...style, ...styles.menuItem }}>
          <Typography
            variant="h6"
            style={{
              fontSize: '.875rem',
              fontWeight: 'bold',
              color: '#000',
              opacity: 100,
            }}
          >
            Billing Account
          </Typography>
        </ListSubheader>
      );
    }

    if (index === 1) {
      return (
        <MenuItem
          onClick={handleSelectAll}
          style={{ ...style, ...styles.menuItem }}
        >
          <Checkbox
            checked={
              selectedItems.length ===
              items.flatMap((customer) => customer.accounts).length
            }
            sx={styles.checkbox}
          />
          <ListItemText
            primary="Select All"
            primaryTypographyProps={styles.menuItem}
          />
        </MenuItem>
      );
    }

    if (isLoading && index === 2) {
      return (
        <MenuItem style={{ ...style, ...styles.menuItem }}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <CircularProgress size={24} />
          </Box>
        </MenuItem>
      );
    }

    let currentIndex = index - 2;
    for (const customer of items) {
      if (currentIndex === 0) {
        return (
          <ListSubheader
            key={customer.id}
            style={{ ...style, ...styles.menuItem }}
          >
            <Typography
              style={{
                fontSize: '.875rem',
                fontWeight: 600,
                color: theme.themeDefault,
                marginRight: '0px',
              }}
            >
              {customer.clientName}
            </Typography>
          </ListSubheader>
        );
      }
      currentIndex -= 1;

      if (currentIndex < customer.accounts.length) {
        const account = customer.accounts[currentIndex];
        return (
          <MenuItem
            key={account.id}
            value={account.id}
            style={{ ...style, ...styles.menuItem }}
            onClick={() => handleCheckboxChange(account.id)}
          >
            <Checkbox
              checked={selectedItems.includes(account.id)}
              sx={styles.checkbox}
            />
            <Tooltip title={account.name} placement="right">
              <ListItemText
                primary={account.name}
                primaryTypographyProps={styles.menuItem}
              />
            </Tooltip>
          </MenuItem>
        );
      }
      currentIndex -= customer.accounts.length;
    }

    return null;
  };

  let disabled = currentAdminIsCustomerAdmin
    ? false
    : !customerIds || customerIds.length === 0;

  return (
    <FormControl
      style={{ maxWidth: '150px', marginRight: '10px' }}
      disabled={disabled}
    >
      <InputLabel id="accounts-multi-select-label" sx={styles.inputLabel}>
        Billing Account
      </InputLabel>
      <Select
        labelId="accounts-multi-select-label"
        label="Billing Account"
        multiple
        value={selectedItems}
        renderValue={renderValue}
        fullWidth
        style={{ borderRadius: '8px' }}
        sx={{
          minWidth: 151,
          maxWidth: 268,
          height: 40,
          color: theme.themeDefault,
          fontSize: '.875rem',
          fontWeight: 600,
          '.MuiOutlinedInput-notchedOutline': {
            borderColor:
              selectedItems.length > 0 ? '#262626' : theme.themeDefault,
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor:
              selectedItems.length > 0 ? '#262626' : theme.themeDefault,
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor:
              selectedItems.length > 0 ? '#262626' : theme.themeDefault,
            borderWidth: '1px',
          },
          ...(currentAdminIsCustomerAdmin
            ? {}
            : (!customerIds || customerIds.length === 0) && {
                pointerEvents: 'none',
                cursor: 'not-allowed',
              }),
        }}
        MenuProps={{
          autoFocus: false,
          PaperProps: {
            style: styles.menuPaper,
          },
        }}
        open={open}
        IconComponent={(props) => (
          <FontAwesomeIcon
            style={{ color: disabled ? 'gery' : theme.themeLight }}
            icon={open ? faAngleUp : faAngleDown}
            {...props}
          />
        )}
        onClose={() => setOpen(false)}
        onOpen={() => {
          if (phoneOnly) {
            setShowMobileModal(true);
            showMobileModalAccounts(
              'Billing Accounts',
              items,
              selectedItems,
              (selected: string[]) => {
                handleChange(selected);
              },
              setShowMobileModal,
              true,
            );
          } else {
            setOpen(true);
          }
        }}
        disabled={disabled}
      >
        <FixedSizeList
          itemCount={
            isLoading
              ? 3
              : items.reduce(
                  (acc, customer) => acc + customer.accounts.length + 1,
                  2,
                )
          }
          itemSize={40}
          height={Math.min(
            items.reduce(
              (acc, customer) => acc + customer.accounts.length + 1,
              2,
            ) *
              45 +
              50,
            500,
          )}
          width="100%"
          style={{
            maxHeight: styles.menuPaper.maxHeight,
            minWidth: styles.menuPaper.maxWidth,
            overflowX: 'hidden',
          }}
        >
          {Row}
        </FixedSizeList>
      </Select>
    </FormControl>
  );
};

export default AccountsSelect;
