import { formatISO as formatISO8601, startOfWeek } from 'date-fns';
import { MONDAY_OF_WEEK, MONDAY } from '@/util/constants';
import { GAEvent } from '@/constants/gaevents';

export const filtersKeysMapper = {
  fillStatus: 'fs',
  dashboardVisibility: 'dv',
  customerIds: 'cids',
  accounts: 'ac',
  skillIds: 'sids',
  regionIds: 'rids',
  dashboardJobStatus: 'djs',
  jobDashboardStatus: 'jds',
};

export const shortenKeys = (obj) =>
  Object.entries(obj).reduce((acc, [key, value]) => {
    acc[filtersKeysMapper[key] || key] = value;
    return acc;
  }, {});

export const expandKeys = (obj) =>
  Object.entries(obj).reduce((acc, [key, value]) => {
    const originalKey =
      Object.keys(filtersKeysMapper).find(
        (k) => filtersKeysMapper[k] === key,
      ) || key;
    acc[originalKey] = value;
    return acc;
  }, {});

export const updateUrlParams = (params: Record<string, string>) => {
  const queryParams = new URLSearchParams(window.location.search);
  Object.keys(params).forEach((key) => {
    queryParams.set(key, params[key]);
  });
  window.history.replaceState(
    {},
    '',
    `${window.location.pathname}?${queryParams.toString()}`,
  );
};

export const getInitialStartDate = (logEvent, currentAgencyId) => {
  const queryParams = new URLSearchParams(window.location.search);

  if (queryParams.has('sd')) {
    const queryDate = queryParams.get('sd');
    const urlDate = startOfWeek(new Date(`${queryDate}T00:00:00`), {
      weekStartsOn: MONDAY,
    });

    if (!isNaN(urlDate.getTime())) {
      logEvent(GAEvent.DashboardStartDateFromUrl, currentAgencyId);
      return urlDate;
    }
  }

  const startDateISO = formatISO8601(MONDAY_OF_WEEK, {
    representation: 'date',
  });
  updateUrlParams({ sd: startDateISO });
  return MONDAY_OF_WEEK;
};
